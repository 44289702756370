import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//slider
import Slider from 'react-slick';
// Dropify
import Dropzone from 'react-dropzone';
// Switch Toggle Button
import Switch from 'react-switch';
// Upload Gambar
import Resizer from 'react-image-file-resizer';
import axios from 'axios';

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA, Post_Marketplace } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from 'reactdatepicker';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: '',
	username: '',
	password: '',
	nik: 0,
	id_role: '',
	status: null,
	no_hp: null,
	email: null
};

function imageFormatter(cell, row) {
	// return "<img src='"+cell+"'/>" ;
	return (
		<>
			<img src={cell} alt="" style={{ width: '250px', height: '80px' }} />
		</>
	);
}


class Campaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				formCampaign: false,
				formProvider: false,
				btnForm: false,
				btnAksi: false,
				formVerifikasi: false,
				statGet: false,
				formReset: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// =========== Select 2 ============

			active_tab_icon: '1',
			// Tab Vertical
			active_tab_vertical: '1',

			// Konfig
			konfig_postpaid: 'none',
			konfig_prepaid: 'none',
			detailProfit: null,
			detailProvider: null,
			detailProfitActive: false,

			// Toggle BUtton
			checked: false,

			post_pulsa: '',
			post_pln: '',
			post_pdam: '',
			post_tv: '',

			pre_pulsa: '',
			pre_pln: '',
			pre_pdam: '',
			pre_tv: '',

			// Data Campaign
			data_campaign: [],
			data_detail_campaign: [],
			foto_desa: '',
			link_gambar: '',
			link_gambar_lama: '',
			link_gambar_show: null,
			get_link_gambar: null,
			data_cek: '',

			// Loading
			loading: true,
			loading_konfig: true,

			// Search Data
			url_cari: ''
		};

	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}

		// console.log('LINKNYA');
		// console.log(link);
		let link_cari = '';
		if (link != '' && this.state.url_cari != '') {
			link_cari = '&' + this.state.url_cari;
		} else if (link == '' && this.state.url_cari != '') {
			link_cari = '?' + this.state.url_cari;
		}

		Get('campaign' + link + link_cari, null, (dtkat) => {
			// Get("banner" + link, null, dtkat => {
			// console.log("Data Pengguna")
			console.log(dtkat)
			if (dtkat.data) {
				this.setState({ data_campaign: dtkat.data.data, loading: false, pagination: dtkat.data });
				this.pagination(dtkat.data);
			} else {
				this.setState({
					data_campaign: [],
					loading: false
					// pagination: dtkat.data
				});
			}
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		// console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// Cari Data Campaign
	cariData = (e) => {
		if (e.key == 'Enter') {
			this.state.loading = true;
			this.forceUpdate();
			// let addRole = {
			// 	q: e.target.value
			// };
			Get('campaign?keyword=' + e.target.value, null, (dtkat) => {
				console.log(dtkat);
				if(dtkat.data){
					this.setState({
						data_campaign: dtkat.data.data,
						loading: false,
						pagination: dtkat.data
					});
				}
				else{
					this.setState({
						data_campaign: [],
						loading: false,
						// pagination: dtkat.data
					});
				}
			});
			// this.state.url_cari = 'keyword=' + e.target.value;
			// this.forceUpdate();
			// this.componentDidMount();
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		if (this.state.id_tingkatUser && this.state.id_jenisUser) {
			let id_wilayah = '';
			if (this.state.id_tingkatUser.value == 'Kabupaten') {
				if (this.state.prvStat == null || this.state.kbktStat == null) {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
					});
				} else {
					id_wilayah = this.state.kbktStat.value;
				}
			} else if (this.state.id_tingkatUser.value == 'Kecamatan') {
				if (this.state.prvStat == null || this.state.kbktStat == null || this.state.kecStat == null) {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
					});
				} else {
					id_wilayah = this.state.kecStat.value;
				}
			}

			let passnya = document.getElementById('password').value;
			if (id_wilayah != '') {
				let add = {
					uuid: this.state.dt.uuid,
					username: document.getElementById('username').value,
					id_wilayah: id_wilayah,
					tingkat: this.state.id_tingkatUser.value,
					no_hp: document.getElementById('no_hp').value,
					email: document.getElementById('email').value,
					nama_lengkap: document.getElementById('nama_lengkap').value,
					nip: document.getElementById('nip').value,
					jenis: this.state.id_jenisUser.value
				};

				console.log('Data Simpan');
				console.log(add);
				let psn = '';
				let resstat = 204;
				let metode = 'create';
				if (add.uuid == '') {
					psn = 'Tambah';
					resstat = 201;
					add.uuid = null;
					add.password = passnya;
				} else {
					psn = 'Ubah';
					resstat = 200;
					metode = 'update';

					if (passnya) {
						add.password = passnya;
					}
				}

				PostStatus('pengguna/' + metode, add.uuid, add, (res) => {
					console.log(res);
					if (res === resstat) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Berhasil ' + psn + ' Data'
						});
						this.state.status.form = false;
						this.state.status.btnForm = false;
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Gagal ' + psn + ' Data'
						});
					}
					this.componentDidMount();
					this.forceUpdate();
				});
			}
		} else {
			if (this.state.id_tingkatUser == null) {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Data Tingkatan Pengguna Tidak Boleh Kosong'
				});
			} else if (this.state.id_jenisUser == null) {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Data Jenis Pengguna Tidak Boleh Kosong'
				});
			} else if (this.state.id_tingkatUser) {
				if (this.state.id_tingkatUser.value == 'Kabupaten') {
					if (this.state.prvStat == null || this.state.kbktStat == null) {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
						});
					}
				} else if (this.state.id_tingkatUser.value == 'Kecamatan') {
					if (this.state.prvStat == null || this.state.kbktStat == null || this.state.kecStat == null) {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
						});
					}
				}
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Data Pengguna Tidak Boleh Kosong'
				});
			}
		}
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log('IDNYA');
		console.log(data);
		this.forceUpdate();

		console.log(data);
		this.state.dt.uuid = data.uuid;
		this.state.dt.email = data.email;
		this.state.dt.id_wilayah = data.id_wilayah;
		this.state.dt.jenis = data.jenis;
		this.state.dt.nama_lengkap = data.nama_lengkap;
		this.state.dt.nip = data.nip;
		this.state.dt.no_hp = data.no_hp;
		this.state.dt.role = data.role;
		this.state.dt.status = data.status;
		this.state.dt.tingkat = data.tingkat;
		this.state.dt.username = data.username;

		this.state.id_tingkatUser = { value: data.tingkat, label: data.tingkat };
		this.state.id_jenisUser = { value: data.jenis, label: data.jenis };

		if (data.tingkat == 'Kabupaten') {
			this.state.prvStat = {
				value: data.data_kabkota.data_provinsi.id,
				label: data.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = { value: data.data_kabkota.id, label: data.data_kabkota.nama };
		} else if (data.tingkat == 'Kecamatan') {
			this.state.prvStat = {
				value: data.data_kecamatan.data_kabkota.data_provinsi.id,
				label: data.data_kecamatan.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = {
				value: data.data_kecamatan.data_kabkota.id,
				label: data.data_kecamatan.data_kabkota.nama
			};
			this.state.kecStat = { value: data.data_kecamatan.id, label: data.data_kecamatan.nama };
		}

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (data) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(data)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (data) => {
		this.hideAlert();
		let psn = 'Hapus';
		let bodyRaw = {
			uuid: data.uuid
		};
		Delete('campaign', bodyRaw, (res) => {
			if (res == 200) {
				axios.get(`${data.gambar}/hapus`).then((resp) => {
					// console.log(resp.data);
				});
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Campaign',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Campaign',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;

		this.state.status.form = false;
		this.state.status.formReset = false;
		this.state.status.formVerifikasi = false;
		this.state.status.formCampaign = false;
		this.state.status.formProvider = false;
		this.state.link_gambar = '';
		this.state.link_gambar_lama = '';
		this.state.data_detail_campaign = [];
		this.state.detailProfit = null;
		this.state.detailProvider = null;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		// console.log(row);
		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: 'Ubah Banner Campaign',
							statUbah: false
						});
						this.ubahDataBanner(row);
					}}
					// disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>
				{'    '}
				<Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => this.konfirmHapus(row)}
					// disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button>
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
				case 'keldes':
					let idDus = this.state.dusStat;
					this.setState({ kelStat: e });
					if (this.state.id_kategori_penduduk.value == 2) {
						this.setState({ dusStat: idDus });
					} else {
						this.setState({ dusStat: null });
					}

					break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// console.log(this.state.rtStat)
					break;
				case 'ukuran_kertas':
					this.state.dt.id_ukuran_kertas = e.value;
					this.setState({ ukkStat: e });
					break;

				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;

				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	componentDidMount() {
		this.fetch({ page: null, where: [] });
		if (typeof this.props.location.tabProps != 'undefined') {
			this.state.active_tab_icon = this.props.location.tabProps;
		}
		if (typeof this.props.location.state != 'undefined') {
			this.state.active_tab_icon = this.props.location.state.tab;
		}

	}

	/// upload file Banner

	processGbr = (d) => {
		// console.log(d[0].type);
		if (d.length == 1) {
			let type = d[0].type.split('/');
			if (
				type[1] == 'jpg' ||
				type[1] == 'jpeg' ||
				type[1] == 'png' ||
				type[1] == 'bmp' ||
				type[1] == 'tiff' ||
				type[1] == 'webp'
			) {
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				// Post_Marketplace('upload-file/banner-ppob-campaign', null, bodyFormData, (data) => {
				Post_Marketplace('upload-file/banner', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
							this.setState({
								foto_desa: uri,
								foto_info: '',
								link_gambar: data.data.results
							});
						},
						'base64'
					);
				});
			} else {
				this.setState({
					foto_desa: '',
					foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
				});
			}
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
			});
		}
	};

	// ============================== Ubah Data ===============================
	ubahDataBanner = (data) => {
		console.log(data);
		this.state.status.btnAksi = true;
		this.state.status.formCampaign = true;
		this.state.judul = 'Ubah Banner';
		this.state.data_detail_campaign.uuid = data.uuid;
		this.state.data_detail_campaign.keterangan = data.keterangan;
		this.state.data_detail_campaign.status_aktif = data.status_aktif == 'Aktif' ? true : false;
		this.state.data_detail_campaign.status_notif = data.status_notif == 'Ya' ? true : false;
		this.state.link_gambar = data.gambar;
		this.state.link_gambar_lama = data.gambar;
		this.forceUpdate();

		this.bukaForm();
	};
	// ========================================================================

	// ================== simpan data banner==================
	simpanCampaign = (e) => {
		e.preventDefault();

		// let link_lama = JSON.parse(this.state.get_link_gambar);
		// let link_baru = this.state.link_gambar;
		// let newGetLinkALL = [];

		if (this.state.link_gambar == '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Campaign Desa',
				pesanAlert: 'Silahkan lengkapi data!'
			});
		} else {
			this.state.status.btnForm = true;
			this.forceUpdate();
			let uuidnya = '';

			let bodyRaw = {
				uuid: document.getElementById('uuid_banner').value,
				gambar: this.state.link_gambar,
				status_aktif: this.state.data_detail_campaign.status_aktif ? 'Aktif' : 'Tidak Aktif',
				status_notif: this.state.data_detail_campaign.status_notif ? 'Ya' : 'Tidak',
				keterangan: document.getElementById('keterangan_banner').value
			};

			console.log(bodyRaw);

			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (bodyRaw.uuid === '') {
				psn = 'Tambah';
				resstat = 200;
				bodyRaw.uuid = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
				if (bodyRaw.gambar === '' || bodyRaw.gambar == this.state.link_gambar_lama) {
					bodyRaw.gambar = this.state.link_gambar_lama;
				} else {
					axios.get(`${this.state.link_gambar_lama}/hapus`).then((resp) => {
						// console.log(resp.data);
					});

					bodyRaw.gambar = bodyRaw.gambar;
				}
			}

			console.log('Data SImpan Banner bos');
			console.log(bodyRaw);

			Post('campaign', bodyRaw.uuid, bodyRaw, (res) => {
				this.state.status.btnForm = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Campaign',
						pesanAlert: 'Berhasil ' + psn + ' Data',
						link_gambar: '',
						data_detail_campaign: []
					});
					this.state.status.formCampaign = false;
					this.forceUpdate();
					this.componentDidMount();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Campaign',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					// this.componentDidMount();
				}
			});
		}
	};

	// ============================= End Simpan Banner =============================

	render() {
		var data = this.state.data_campaign;

		// var data = require('../assets/json/company');

		const frmDef = {
			uuid: '',
			username: '',
			password: '',
			nik: '',
			id_role: '',
			status: null,
			no_hp: null,
			email: null
		};
		const columns = [
			{
				dataField: 'gambar',
				text: 'Gambar',
				sort: true,
				formatter: imageFormatter,
				align: 'center'
			},
			{
				dataField: 'keterangan',
				text: 'Keterangan',
				sort: true
			},
			{
				dataField: 'status_notif',
				text: 'Notifikasi',
				sort: true
			},
			{
				dataField: 'status_aktif',
				text: 'Status Aktif',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];
		

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<Row>
										<Col sm="12">
											<div className="row" style={{ flexDirection: 'row-reverse' }}>
												<div className="col-sm-6 text-right">
													<Button
														size="sm"
														color="success"
														className="btn-square"
														style={{ marginBottom: '15px' }}
														onClick={() => {
															this.setState({
																judul: 'Tambah Campaign',
																dt: frmDef
															});
															this.state.dt.uuid = null;
															this.state.status.formCampaign = true;
															this.forceUpdate();
															this.bukaForm();
														}}
													>
														Tambah Campaign
													</Button>
												</div>
												<div className="col-sm-6">
													<input
														type="text"
														className="form-control"
														id="cariTable"
														placeholder="Cari Disini"
														onKeyPress={this.cariData}
														style={{ marginBottom: '15px' }}
													/>
												</div>
											</div>
											{this.state.loading ? (
												<div align="center">
													<br />
													<br />
													<img
														src={require('../assets/images/loading-bos.gif')}
														style={{
															width: '100%',
															borderRadius: '10px',
															width: '70px'
														}}
													/>
												</div>
											) : (
												<div>
													<BootstrapTable
														keyField="id"
														data={data}
														columns={columns}
													/>
													<div className="pull-right text-white">
														{this.state.awal}
														{this.state.sebelum}
														{this.state.hal.map((dt) => {
															return dt;
														})}
														{this.state.setelah}
														{this.state.akhir}
													</div>
												</div>
											)}
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal
					open={this.state.status.formCampaign}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpanCampaign}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid_banner"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.data_detail_campaign.uuid}
								value={this.state.data_detail_campaign.uuid}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Gambar Campaign
								</Label>
								<Col sm="9">
									<Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)} maxFiles={12}>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													{...getRootProps()}
													style={{
														minHeight: '100px',
														paddingTop: '40px',
														padding: '5px 5px 5px 5px',
														border: '1px solid #d2d2d2'
													}}
												>
													<input {...getInputProps()} />
													<div
														style={{
															justifyContent: 'center',
															alignItems: 'center'
														}}
													>
														<p className="text-center">
															{this.state.link_gambar ? (
																<img
																	src={this.state.link_gambar}
																	style={{
																		// minHeight: '50px',
																		// minWidth: '50px'
																		width: '470px'
																	}}
																/>
															) : (
																<Label
																	className="col-form-label text-center"
																	htmlFor="inputEmail3"
																>
																	{/* Drag 'n' drop some files here, or click to select
																	files */}
																	Seret file gambar kesini, atau klik untuk memilih gambar
																</Label>
															)}
															{this.state.foto_info ? this.state.foto_info : ''}
														</p>
													</div>
												</div>
											</section>
										)}
									</Dropzone>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Keterangan Campaign
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="keterangan_banner"
										type="text"
										placeholder="Masukkan Keterangan"
										required
										defaultValue={this.state.data_detail_campaign.keterangan}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status Notifikasi
								</Label>
								<Col sm="9">
									<Switch 
										onChange={(e) => {
											console.log(e);
											this.setState({ data_detail_campaign : {...this.state.data_detail_campaign, status_notif: e} })
										}}
										checked={this.state.data_detail_campaign.status_notif} 
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status Aktif
								</Label>
								<Col sm="9">
									<Switch 
										onChange={(e) => {
											console.log(e);
											this.setState({ data_detail_campaign : {...this.state.data_detail_campaign, status_aktif: e} })
										}}
										checked={this.state.data_detail_campaign.status_aktif} 
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
				<Modal
					open={this.state.status.formProvider}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form">
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{this.state.detailProvider ? (
								<div>
									{this.state.detailProvider.nominal.map((nominal, index) => {
										return (
											<FormGroup className="row">
												<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
													Nominal {nominal.nominal}
												</Label>
												<Col sm="9">
													<div className="row">
														{nominal.server.map((f, index2) => {
															return (
																Object.keys(f).map((g, index3) => {
																	return (
																		<>
																			<div className="col-sm-3">
																				{g}
																			</div>
																			<div className="col-sm-9">
																			<input
																				className="form-control"
																				// id="keterangan_banner"
																				type="text"
																				placeholder="Masukkan Keterangan"
																				required
																				defaultValue={f[g]}
																			/>
																			</div>
																		</>
																	)
																})
															)
														})}
													</div>
												</Col>
											</FormGroup>
										)
									})}
								</div>
								
							) : ''}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Keterangan Banner
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="keterangan_banner"
										type="text"
										placeholder="Masukkan Keterangan"
										required
										defaultValue={this.state.data_detail_campaign.keterangan}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Campaign;
