import React, { Component } from "react";
import {Link} from 'react-router-dom';

// Import authService
import { Redirect } from "react-router-dom";
import { DataToken } from "../../../function/Format";

class UserMenu extends Component {
  state = {
    navigate: false,
    akun: DataToken(sessionStorage.getItem("access_token"))
  };
  logOut(e) {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    this.setState({ navigate: true });
  }
  
  render() {
    const { navigate, akun } = this.state;
    // console.log(akun);
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }
    return (
      <li className="onhover-dropdown">
        <div className="media  align-items-center">
          <img
            className="align-self-center pull-right mr-2"
            src={require("../../../assets/images/dashboard/user.png")}
            alt="header-user"
          />
          <div className="media-body">
            <h6 className="m-0 txt-dark f-16">
            {/* {akun.sub.username} */}
              <i className="fa fa-angle-down pull-right ml-2"></i>
            </h6>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20">
          {/* <li>
            <a href="#">
              <i className="icon-user"></i>
              Edit Profile
            </a>
          </li> */}
          {/* <li>
                        <a href="#">
                            <i className="icon-email"></i>
                            Inbox
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="icon-check-box"></i>
                            Task
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="icon-comments"></i>
                            Chat
                        </a>
                    </li> */}

          {/* {this.penggunaView} */}
          {/* {akun.sub.data_role.nama_role != "Admin" ? (
            <li>
              <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
                <i className="icon-user"></i>
                Manajemen Pengguna
              </Link>
            </li>
          ) : (
            null
          )} */}

          <li>
            <a href="#" onClick={this.logOut.bind(this)}>
              <i className="icon-power-off"></i>
              Logout
            </a>
          </li>
        </ul>
      </li>
    );
  }
}

export default UserMenu;
