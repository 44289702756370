import axios from "axios";

// dev
// const API_URL = "http://165.22.52.148:2001/web/";
// const API_URL = "https://ws-ppob-dev.digidesa.com/web/";
// const API_URL = "https://ws-ppob-demo.digidesa.com/web/";

// prod
// const API_URL = "http://165.22.52.148:2002/web/";
const API_URL = "https://ws-ppob-prod.digidesa.com/web/";

// const API_URL2 = "https://ws-marketplace-desa-univ.digidesa.com/api/v1/web/";

const API_URL2 = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/web/';

export const JENIS_DESA = "Desa";

export function Get(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "public";

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				if (err.response) {
					cb(err.response.data);
					// cb(err.response.headers);
					// cb(err.response.status);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				}
				// cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Delete(table, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";

	axios
		.delete(`${API_URL + table}`, {
			headers: { Authorization: `Bearer ${token}` },
			data: data
		})
		.then(response => response.status)
		.then(status => {
			cb(status);
		})
		.catch(err => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.put(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	axios
		.put(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	axios
		.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {
	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(response => {
			cb(response);
		});
}
export function PostStatus(table, id, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function PostUser(table, id, data, cb) {
	if (id === null) {
		axios
			.post("http://localhost/ppdx/web/" + table, data, {
				headers: { "Content-Type": "multipart/form-data" }
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	}
}

// =================== Koneksi Marketplace

let token_marketplace = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiZWFyZXIiLCJzdWIiOnsidXVpZCI6IjM5Mjk5MWU5LWU0NDYtNGQ1ZS04YWI4LTJkYzk4NmJmZjdkZiIsInVzZXJuYW1lIjoiYWRtaW4xMjMiLCJyb2xlIjoxLCJub19ocCI6IjA4MDgwODgiLCJlbWFpbCI6ImFkbWluMTIzQGdtYWlsLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIwLTA5LTExIDExOjM3OjM2IiwidXBkYXRlZF9hdCI6IjIwMjAtMTAtMTYgMTc6MDg6NTgiLCJmb3RvIjoiaHR0cHM6XC9cL3N0YXRpYy50aGVub3VucHJvamVjdC5jb21cL3BuZ1wvMTM4OTI2LTIwMC5wbmciLCJuYW1hX2xlbmdrYXAiOm51bGx9fQ.aIhNtBki9W671i0iY8uCqnOnmJHmh_w3d18NfMBrT_Y"

export function Get_Marketplace(table, id, cb) {
	let token = token_marketplace

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL2 + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				if (err.response) {
					cb(err.response.data);
					// cb(err.response.headers);
					// cb(err.response.status);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				}
				// cb(err);
			});
	} else {
		axios
			.get(`${API_URL2 + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Delete_Marketplace(table, id, cb) {
	let token = token_marketplace

	axios
		.delete(`${API_URL2 + table}/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		})
		.then(response => response.status)
		.then(status => {
			cb(status);
		})
		.catch(err => {
			cb(err);
		});
}

export function Post_Marketplace(table, id, data, cb) {
	let token = token_marketplace
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL2 + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL2 + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Put_Marketplace(table, id, data, cb) {
	axios
		.put(`${API_URL2 + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function Patch_Marketplace(table, id, data, cb) {
	axios
		.patch(`${API_URL2 + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export default API_URL;
