import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//slider
import Slider from 'react-slick';
// Dropify
import Dropzone from 'react-dropzone';
// Switch Toggle Button
import Switch from 'react-switch';
// Upload Gambar
import Resizer from 'react-image-file-resizer';
import axios from 'axios';

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA, Post_Marketplace } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from 'reactdatepicker';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: '',
	username: '',
	password: '',
	nik: 0,
	id_role: '',
	status: null,
	no_hp: null,
	email: null
};

const groupBy = key => array =>
	array.reduce(
		(objectsByKeyValue, obj) => ({
			...objectsByKeyValue,
			[obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
		}),
		{}
	);

class PostPaid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dataPostpaidDigiflaz: [],
			listPostpaidSKU: {},
			listPostpaidBrand: [],
			dataKonfig: null,
			pagination: [],
			status: {
				form: false,
				formBanner: false,
				formProvider: false,
				btnForm: false,
				btnAksi: false,
				formVerifikasi: false,
				statGet: false,
				formReset: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// =========== Select 2 ============

			active_tab_icon: '1',
			// Tab Vertical
			active_tab_vertical: '1',

			// Konfig
			konfig_postpaid: 'none',
			konfig_prepaid: 'none',
			detailProfit: null,
			detailProvider: null,
			detailProfitActive: false,
			detailPostpaid: {
				product_name: '',
				brand: '',
				buyer_sku_code: '',
				category: '',
				// nama_server: '',
				nominal_keuntungan: '',
				nominal_penjualan: '',
			},
			currentPostpaidIndex: null,

			// Toggle BUtton
			checked: false,

			post_pulsa: '',
			post_pln: '',
			post_pdam: '',
			post_tv: '',

			pre_pulsa: '',
			pre_pln: '',
			pre_pdam: '',
			pre_tv: '',
			listPostpaid: [
				'PLN PASCABAYAR',
				'BPJS KESEHATAN',
				'HP PASCABAYAR',
				'INTERNET PASCABAYAR',
				'MULTIFINANCE',
			],
			listBrand: [],
			detailListPostpaid: null,

			// Data Banner
			data_banner: [],
			data_detail_banner: [],
			foto_desa: '',
			link_gambar: '',
			link_gambar_lama: '',
			link_gambar_show: null,
			get_link_gambar: null,
			data_cek: '',

			// Loading
			loading: true,
			loading_konfig: true,

			// Search Data
			url_cari: ''
		};

		// this.handleChangeUmum = this.handleChangeUmum.bind(this);
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// Toggle Vertical Tab
	toggle_vertical(tab) {
		if (this.state.active_tab_vertical !== tab) {
			this.setState({
				active_tab_vertical: tab
			});
		}
	}

	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}

		console.log('LINKNYA');
		console.log(link);
		let link_cari = '';
		if (link != '' && this.state.url_cari != '') {
			link_cari = '&' + this.state.url_cari;
		} else if (link == '' && this.state.url_cari != '') {
			link_cari = '?' + this.state.url_cari;
		}

		this.setState({ loading: false })

		// Get('banner' + link + link_cari, null, (dtkat) => {
		// 	if (dtkat.data) {
		// 		this.setState({ 
		// 			data_banner: dtkat.data.data, 
		// 			loading: false, 
		// 			pagination: dtkat.data
		// 		 });
		// 		this.pagination(dtkat.data);
		// 	} else {
		// 		this.setState({
		// 			data_banner: [],
		// 			loading: false
		// 			// pagination: dtkat.data
		// 		});
		// 	}
		// });
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// Cari Data Banner
	cariData = (e) => {
		if (e.key == 'Enter') {
			this.state.loading = true;
			// this.forceUpdate();
			// let addRole = {
			// 	q: e.target.value
			// };
			// Get('banner?keyword=' + e.target.value, null, (dtkat) => {
			// 	if(dtkat.data){
			// 		this.setState({
			// 			data_banner: dtkat.data.data,
			// 			loading: false,
			// 			pagination: dtkat.data
			// 		});
			// 	}
			// 	else{
			// 		this.setState({
			// 			data_banner: [],
			// 			loading: false,
			// 			// pagination: dtkat.data
			// 		});
			// 	}
			// });
			this.state.url_cari = 'keyword=' + e.target.value;
			this.forceUpdate();
			this.componentDidMount();
		}
	};

	// ========================================================================

	editableCellHandle = (oldData, dataField, newValue) => {
		let bodyRaw = {}
		if (this.state.dataKonfig) {
			bodyRaw = {
				uuid: this.state.dataKonfig.uuid,
				prepaid: this.state.dataKonfig.prepaid,
				postpaid: this.state.dataKonfig.postpaid,
			}
		}

		let category = oldData.category
		let brand = oldData.brand
		let nama_server = oldData.nama_server
		let product_name = oldData.product_name
		let buyer_sku_code = oldData.buyer_sku_code
		let nominal_penjualan = parseInt(oldData.nominal_penjualan)
		let nominal_modal = parseInt(oldData.nominal_modal)
		// let nominal_het = parseInt(oldData.nominal_het)
		let nominal_keuntungan = parseInt(oldData.nominal_keuntungan)

		switch (dataField) {
			case 'category':
				category = newValue
				break;
			case 'brand':
				brand = newValue
				break;
			case 'nama_server':
				nama_server = newValue
				break;
			case 'product_name':
				product_name = newValue
				break;
			case 'buyer_sku_code':
				buyer_sku_code = newValue
				break;
			case 'nominal_penjualan':
				nominal_penjualan = parseInt(newValue)
				break;
			case 'nominal_modal':
				nominal_modal = parseInt(newValue)
				break;
			// case 'nominal_het':
			// 	nominal_het = parseInt(newValue)
			// 	break;
			case 'nominal_keuntungan':
				nominal_keuntungan = parseInt(newValue)
				break;
		}

		let isiData = JSON.parse(bodyRaw.postpaid)

		isiData[this.state.currentDetailIndex] = { ...isiData[this.state.currentDetailIndex],
			category,
			brand,
			// nama_server,
			product_name,
			buyer_sku_code,
			nominal_penjualan,
			// nominal_modal,
			nominal_keuntungan,
			// nominal_het,
		}

		let psn = 'Memperbarui'

		bodyRaw.postpaid = JSON.stringify(isiData)

		Post('konfigurasi-admin', bodyRaw.uuid, bodyRaw, (res) => {
			if (res.status === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Konfigurasi Profit Perusahaan',
					pesanAlert: 'Berhasil ' + psn + ' Data Profit Perusahaan',
					currentDetailIndex: null
				});
				this.componentDidMount();
				// this.tutupForm();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Konfigurasi Profit Perusahaan',
					pesanAlert: 'Gagal ' + psn + ' Data Profit Perusahaan',
					currentDetailIndex: null
				});
				// this.componentDidMount();
				// this.tutupForm();
			}
		});
	}

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		console.log(this.state.currentPostpaidIndex);

		let bodyRaw = {}
		let psn = ''

		if (this.state.dataKonfig) {
			bodyRaw = this.state.dataKonfig
		} else {
			bodyRaw = {
				prepaid: '',
				postpaid: '',
			}
		}
		
		// Jika data sudah pernah diinputkan sebelumnya (PUT)
		// if (this.state.currentPostpaidIndex != null && this.state.currentPostpaidIndex !== -1) {
		if (this.state.dataKonfig && this.state.dataKonfig.uuid != '') {
			if (this.state.detailPostpaid && this.state.detailPostpaid.brand != ''  && this.state.detailPostpaid.buyer_sku_code != ''){
				let category = this.state.detailPostpaid.category
				let brand = this.state.detailPostpaid.brand
				// let nama_server = this.state.detailPostpaid.nama_server
				// let buyer_sku_code = document.getElementById('buyer_sku_code').value
				let buyer_sku_code = this.state.detailPostpaid.buyer_sku_code
				// let nominal_penjualan = document.getElementById('nominal_penjualan').value
				let nominal_penjualan = 0
				let product_name = document.getElementById('product_name').value
				let nominal_keuntungan = parseInt(document.getElementById('nominal_keuntungan').value)
	
				let postpaid = this.state.data

				// Jika edit
				if (this.state.currentPostpaidIndex || this.state.currentPostpaidIndex == 0) {
					postpaid[this.state.currentPostpaidIndex] = {
						product_name,
						category,
						brand,
						// nama_server,
						buyer_sku_code,
						nominal_penjualan,
						nominal_keuntungan,
					}
				} else { // Jika tambah
					postpaid.push({
						product_name,
						category,
						brand,
						// nama_server,
						buyer_sku_code,
						nominal_penjualan,
						nominal_keuntungan,
					})
				}
	
				psn = 'Memperbarui'
	
				if (this.state.dataKonfig) {
					bodyRaw.postpaid = JSON.stringify(postpaid)
				}

				this.tutupForm()
				this.setState({ loading: true })
	
				Post('konfigurasi-admin', bodyRaw.uuid, bodyRaw, (res) => {
					if (res.status === 200) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Berhasil ' + psn + ' Data Profit',
							// detailListPostpaid: null
						});
						this.componentDidMount();
						// this.tutupForm();
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Gagal ' + psn + ' Data Profit'
						});
						// this.componentDidMount();
						this.tutupForm();
					}
				});
			} else {
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: "Data Belum Lengkap",
					pesanAlert: "Silahkan isi seluruh data yg diperlukan dengan benar"
				});
			}

		} else { // Jika data belum pernah diinputkan sebelumnya (POST)
			if (this.state.detailPostpaid && this.state.detailPostpaid.brand != '' && this.state.detailPostpaid.buyer_sku_code != '') {
				psn = 'Tambah'
				
				let category = this.state.detailPostpaid.category
				let brand = this.state.detailPostpaid.brand
				// let nama_server = this.state.detailPostpaid.nama_server
				// let buyer_sku_code = document.getElementById('buyer_sku_code').value
				let buyer_sku_code = this.state.detailPostpaid.buyer_sku_code
				// let nominal_penjualan = document.getElementById('nominal_penjualan').value
				let nominal_penjualan = 0
				let product_name = document.getElementById('product_name').value
				let nominal_keuntungan = parseInt(document.getElementById('nominal_keuntungan').value)

				let postpaid = this.state.data

				postpaid.push({
					product_name,
					category,
					brand,
					// nama_server,
					buyer_sku_code,
					nominal_penjualan,
					nominal_keuntungan,
				})

				bodyRaw.postpaid = JSON.stringify(postpaid)


				Post('konfigurasi-admin', bodyRaw.uuid, bodyRaw, (res) => {
					if (res.status === 200) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Berhasil ' + psn + ' Data Profit'
						});
						this.componentDidMount();
						this.tutupForm();
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Gagal ' + psn + ' Data Profit'
						});
						// this.componentDidMount();
						this.tutupForm();
					}
				});

			} else {
				console.log('Pilih brand terlebih dahulu');
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: "Data Belum Lengkap",
					pesanAlert: "Silahkan isi seluruh data yg diperlukan dengan benar"
				});
			}
		}

	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log('IDNYA');
		console.log(data);
		this.forceUpdate();

		console.log(data);
		this.state.dt.uuid = data.uuid;
		this.state.dt.email = data.email;
		this.state.dt.id_wilayah = data.id_wilayah;
		this.state.dt.jenis = data.jenis;
		this.state.dt.nama_lengkap = data.nama_lengkap;
		this.state.dt.nip = data.nip;
		this.state.dt.no_hp = data.no_hp;
		this.state.dt.role = data.role;
		this.state.dt.status = data.status;
		this.state.dt.tingkat = data.tingkat;
		this.state.dt.username = data.username;

		this.state.id_tingkatUser = { value: data.tingkat, label: data.tingkat };
		this.state.id_jenisUser = { value: data.jenis, label: data.jenis };

		if (data.tingkat == 'Kabupaten') {
			this.state.prvStat = {
				value: data.data_kabkota.data_provinsi.id,
				label: data.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = { value: data.data_kabkota.id, label: data.data_kabkota.nama };
		} else if (data.tingkat == 'Kecamatan') {
			this.state.prvStat = {
				value: data.data_kecamatan.data_kabkota.data_provinsi.id,
				label: data.data_kecamatan.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = {
				value: data.data_kecamatan.data_kabkota.id,
				label: data.data_kecamatan.data_kabkota.nama
			};
			this.state.kecStat = { value: data.data_kecamatan.id, label: data.data_kecamatan.nama };
		}

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (data) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(data)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (data) => {
		this.hideAlert();
		let psn = 'Hapus';
		if (this.state.dataKonfig) {
			let bodyRaw = {
				uuid: this.state.dataKonfig.uuid,
				prepaid: this.state.dataKonfig.prepaid,
				postpaid: this.state.dataKonfig.postpaid,
			};

			let postpaid = this.state.data
			postpaid = postpaid.filter(item => item !== data)

			bodyRaw.postpaid = JSON.stringify(postpaid)

			this.setState({ 
				// detailListPostpaid: null, 
				loading: true 
			})

			Post('konfigurasi-admin', bodyRaw.uuid, bodyRaw, (res) => {
				psn = 'Hapus'
				if (res.status === 200) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Konfigurasi Profit',
						pesanAlert: 'Berhasil ' + psn + ' Data Profit'
					});
					this.componentDidMount();
					this.tutupForm();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Konfigurasi Profit',
						pesanAlert: 'Gagal ' + psn + ' Data Profit'
					});
					// this.componentDidMount();
					this.tutupForm();
				}
			});
		}
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;

		this.state.status.form = false;
		this.state.status.formReset = false;
		this.state.status.formVerifikasi = false;
		this.state.status.formBanner = false;
		this.state.status.formProvider = false;
		this.state.link_gambar = '';
		this.state.data_detail_banner = [];
		this.state.detailProfit = null;
		this.state.detailProvider = null;
		// this.state.currentPostpaidIndex = null;

		this.state.detailPostpaid = {
			product_name: '',
			brand: '',
			buyer_sku_code: '',
			category: '',
			// nama_server: '',
			nominal_keuntungan: '',
			nominal_penjualan: '',
		}
		
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		// console.log(row);
		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						let currentPostpaidIndex = this.state.data.indexOf(row)

						this.setState({
							judul: 'Ubah Profit',
							statUbah: false,
							detailPostpaid: row,
							currentPostpaidIndex
						});
						this.bukaForm();
					}}
					// disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>
				{'    '}
				<Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => {
						let currentPostpaidIndex = this.state.data.indexOf(row)

						this.setState({
							detailPostpaid: row,
							currentPostpaidIndex
						});
						this.konfirmHapus(row)
					}}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button>
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
				case 'brand':
					this.setState({ detailPostpaid: {...this.state.detailPostpaid, brand: e.value} });
					break;
				case 'nama_server':
					this.setState({ detailPostpaid: {...this.state.detailPostpaid, nama_server: e.value} });
					break;
			}
		} else {
			switch (sel) {
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
				case 'brand':
					this.setState({ detailPostpaid: {...this.state.detailPostpaid, brand: ''} });
					break;
				case 'nama_server':
					this.setState({ detailPostpaid: {...this.state.detailPostpaid, nama_server: ''} });
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	componentDidMount() {

		Get('layanan/postpaid', null, (dtkat) => {
			console.log(dtkat);
			if (dtkat.data) {
				let dataPostpaidDigiflaz = dtkat.data

				const groupByCategory = groupBy('category');
				const groupBySKU = groupBy('buyer_sku_code');
				const groupByBrand = groupBy('brand');

				let categorized = groupByCategory(dataPostpaidDigiflaz);
				let branded = groupByBrand(dataPostpaidDigiflaz);

				let brandedSKU = {}
				let categorizedBrand = {}
				Object.keys(categorized).map((attribute, index) => {
					categorizedBrand[attribute] = groupByBrand(categorized[attribute])
				})
				Object.keys(branded).map((attribute, index) => {
					brandedSKU[attribute] = groupBySKU(branded[attribute])
				})

				// Set list sku postpaid
				let listPostpaidSKU = []
				Object.keys(brandedSKU).map((brand) => {
					listPostpaidSKU[brand] = []
					Object.keys(brandedSKU[brand]).map((sku) => {
						listPostpaidSKU[brand].push({
							value: sku,
							label: sku,
						})
					})
				})

				// Set list brand postpaid
				let listPostpaidBrand = []
				Object.keys(categorizedBrand).map((category) => {
					Object.keys(categorizedBrand[category]).map((brand) => {
						listPostpaidBrand[brand] = []
						listPostpaidBrand[brand].push({
							value: brand,
							label: brand,
						})
					})
				})

				this.setState({ 
					listPostpaidSKU, 
					listPostpaidBrand
				})

				Get('konfigurasi-admin', null, (resData) => {
					console.log(resData);
					if (resData.data) {
						this.setState({ dataKonfig: resData.data })
						if (resData.data.postpaid.trim() != '') {
							// let data = JSON.parse(resData.data.postpaid)
							
							let dataInit = JSON.parse(resData.data.postpaid)
							console.log(dataInit);
							let data = []
							dataInit.map((val) => {
								delete val.nominal_het
								data.push(val)
								return ''
							})
							console.log(data);

							this.setState({ data })
			
							// Pengelompokan Menu Tabulasi dan filter
							const groupByBrand = groupBy('brand');
			
							let detailListPostpaid = [];

							if (data.length > 0) {
								detailListPostpaid = groupByBrand(data);
								console.log(detailListPostpaid);
								this.setState({ detailListPostpaid })
							}
							this.setState({ loading: false });
							
						} else {
							console.log('masuk inisiasi');
							let newDataPostpaid = []
	
							dataPostpaidDigiflaz.map((val, index) => {
								let namaServer = val.buyer_sku_code.split('-')

								newDataPostpaid.push({
									brand: val.brand,
									product_name: val.product_name,
									buyer_sku_code: val.buyer_sku_code,
									category: val.category,
									// nama_server: namaServer[0],
									nominal_modal: parseInt(val.price),
									nominal_penjualan: 0,
									nominal_keuntungan: 0,
									// nominal_het: parseInt(val.price) + 1000,
								})
							})
							console.log(newDataPostpaid);
							console.log(JSON.stringify(newDataPostpaid));

							let bodyRaw = resData.data
							bodyRaw.postpaid = JSON.stringify(newDataPostpaid)

							Post('konfigurasi-admin', bodyRaw.uuid, bodyRaw, (res) => {
								if (res.status === 200) {
									this.setState({
										show: true,
										basicType: 'success',
										basicTitle: 'Data Konfigurasi Profit',
										pesanAlert: 'Berhasil Inisiasi Data Profit Postpaid',
										// detailListPostpaid: null
									});
									this.componentDidMount();
									// this.tutupForm();
								} else {
									this.setState({
										show: true,
										basicType: 'danger',
										basicTitle: 'Data Konfigurasi Profit',
										pesanAlert: 'Gagal Inisiasi Data Profit Postpaid'
									});
									// this.componentDidMount();
									this.tutupForm();
								}
							});

						}
		
					} else {
						this.setState({
							dataKonfig: null
						});
					}
				});

			}
		})
	}

	// ============================== Ubah Konfig ===============================

	ubahKonfig = (type_konfig) => {
		// e.preventDefault();
		switch (type_konfig) {
			case 'postpaid':
				this.setState({ konfig_postpaid: 'inherit' });
				break;
			case 'prepaid':
				this.setState({ konfig_prepaid: 'inherit' });
				break;
		}
		this.forceUpdate();
	};
	// ========================================================================

	render() {
		const { photoIndex, isOpen } = this.state;

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;

		var data = this.state.data;

		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'product_name',
				text: 'Product Name',
				sort: true,
				editable: true
			},
			{
				dataField: 'brand',
				text: 'Brand',
				sort: true,
				editable: false
				// textAlign: "center",
				// align: 'center'
			},
			// {
			// 	dataField: 'nama_server',
			// 	text: 'Server',
			// 	sort: true
			// },
			{
				dataField: 'buyer_sku_code',
				text: 'SKU Code',
				sort: true,
				editable: false
			},
			// {
			// 	// dataField: 'nominal_penjulan',
			// 	dataField: 'nominal_penjualan',
			// 	text: 'Nominal Penjualan',
			// 	sort: true
			// },
			{
				dataField: 'nominal_keuntungan',
				text: 'Nominal Profit',
				sort: true,
				editable: true,
				formatter: (cell, obj) => {
					let nominal_keuntungan = new Intl.NumberFormat(['ban', 'id']).format(obj.nominal_keuntungan)
					return 'Rp. ' + nominal_keuntungan
				}
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		let listBrand = null
		let listServer = [
			{value: 'A1', label: 'A1'},
			{value: 'A2', label: 'A2'},
			{value: 'A3', label: 'A3'},
		]
		let listSKU = []

		if (this.state.detailPostpaid && this.state.detailPostpaid.brand) {
			let brand = this.state.detailPostpaid.brand

			listSKU = this.state.listPostpaidSKU[brand]
			listBrand = this.state.listPostpaidBrand[brand]
		}
		
		let bootstrapTable = (brand) => (
			<div>
				<ToolkitProvider 
					keyField="uuid_surat" 
					data={this.state.detailListPostpaid[brand]} 
					columns={columns}
					search
				>
					{
						 props => (
							<div>
								<div className="row">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-8" style={{ marginBottom: "15px" }}>
												<Button
													size="sm"
													color="success"
													className="btn-square"
													style={{ marginBottom: '15px' }}
													onClick={() => {
														this.setState({
															judul: 'Tambah Data Profit ' + brand,
															// currentPostpaidIndex: null,
															detailPostpaid: {...this.state.detailPostpaid, brand}
														});
														console.log(brand);
														console.log({...this.state.detailPostpaid, brand});
														this.bukaForm();
													}}
												>
													Tambah Data
												</Button>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row" style={{ justifyContent: "flex-end" }}>
											<div className="col-md-8" style={{ display: "flex", justifyContent: "flex-end" }}>
												<SearchBar { ...props.searchProps } />
											</div>
										</div>
									</div>
								</div>
								<Row>
									<Col sm="12">
										<BootstrapTable
											pagination={ paginationFactory({
												sizePerPage: 10
											}) }
											{ ...props.baseProps }
											cellEdit={ cellEditFactory({
												mode: 'dbclick',
												onStartEdit: (oldValue, newValue, row, column) => {
													let bodyRaw = {}
													if (this.state.dataKonfig) {
														bodyRaw = {
															uuid: this.state.dataKonfig.uuid,
															prepaid: this.state.dataKonfig.prepaid,
															postpaid: this.state.dataKonfig.postpaid,
														}
													}
													
													let isiData = JSON.parse(bodyRaw.postpaid)

													let currentDetailIndex = isiData.map(function(e) { return e.buyer_sku_code; }).indexOf(oldValue.buyer_sku_code);
													this.setState({ currentDetailIndex })
													// let currentDetailIndex = row
													// this.setState({ currentDetailIndex })
												},
												afterSaveCell: (oldValue, newValue, row, column) => {
													let oldData = row
													let dataField = column.dataField
													this.editableCellHandle(oldData, dataField, newValue)
												}							
											}) }
										/>
									</Col>
								</Row>
							</div>
						)
					}
				</ToolkitProvider>
				{/* {data.length != 0 ? (
					<div className="pull-right text-white">
						{this.state.awal}
						{this.state.sebelum}
						{this.state.hal.map((dt) => {
							return dt;
						})}
						{this.state.setelah}
						{this.state.akhir}
					</div>
				) : (
					''
				)} */}
			</div>
		);

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									{/* {this.state.detailListPostpaid && !this.state.loading ? ( */}
									{!this.state.loading ? (
										<div>
											<Nav tabs className="tabs-color">
												{Object.keys(this.state.listPostpaidSKU).map((brand, index) => {
													return (
														<NavItem style={{ cursor: 'pointer' }}>
															<NavLink
																className={classnames({
																	active: this.state.active_tab_icon === (index + 1).toString()
																})}
																onClick={() => {
																	this.toggle_icon((index + 1).toString());
																}}
															>
																<i className="icofont icofont-ui-home" />
																{brand}
															</NavLink>
														</NavItem>
													)
												})}
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												{Object.keys(this.state.listPostpaidSKU).map((brand, index) => {

													return (
														<TabPane tabId={(index + 1).toString()}>
															<div style={{ marginTop: 15 }}>
																{this.state.detailListPostpaid && typeof this.state.detailListPostpaid[brand] != 'undefined' ? bootstrapTable(brand) : (
																	<div className="row">
																		<div className="col-md-8"style={{ marginBottom: "15px" }} >
																			<Button
																				size="sm"
																				color="success"
																				className="btn-square"
																				style={{ marginBottom: '15px' }}
																				onClick={() => {
																					console.log(brand);
																					console.log(index);
																					this.setState({
																						judul: 'Tambah Data Profit ' + brand,
																						currentPostpaidIndex: null,
																						detailPostpaid: {...this.state.detailPostpaid, brand}
																					});
																					this.bukaForm();
																				}}
																			>
																				Tambah Data
																			</Button>
																		</div>
																	</div>
																)}
															</div>
														</TabPane>
													)
												})}
											</TabContent>
										</div>
									) : (
										<div align="center">
											<br/><br/>
											<img
												src={require('../assets/images/loading-bos.gif')}
												style={{
													width: '100%',
													borderRadius: '10px',
													width: '70px'
												}}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Product Name
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="product_name"
										type="text"
										placeholder="Masukkan Nama Produk"
										required
										defaultValue={this.state.detailPostpaid.product_name}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Brand
								</Label>
								<Col sm="9">
									<Select
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue(e, "brand");
											// console.log(e)
										}}
										defaultValue={this.state.detailPostpaid.brand != '' ? {
											value : this.state.detailPostpaid.brand,
											label : this.state.detailPostpaid.brand,
										} : null}
										name="brand"
										// options={[this.state.listBrand[category]]}
										options={listBrand}
										placeholder="Pilih Brand"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Buyer SKU Code
								</Label>
								<Col sm="9">
									{/* <input
										className="form-control"
										id="buyer_sku_code"
										type="text"
										placeholder="Masukkan Kode SKU"
										// required
										defaultValue={this.state.detailPostpaid.buyer_sku_code}
									/> */}
									<Select
										classNamePrefix="select"
										onChange={e => {
											// this.changeSelectValue(e, "buyer_sku_code");
											this.setState({ detailPostpaid: {...this.state.detailPostpaid, buyer_sku_code: e.value} })
										}}
										defaultValue={this.state.detailPostpaid.buyer_sku_code != '' ? {
											value : this.state.detailPostpaid.buyer_sku_code,
											label : this.state.detailPostpaid.buyer_sku_code,
										} : null}
										name="buyer_sku_code"
										options={listSKU}
										placeholder="Pilih SKU"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nominal Keuntungan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nominal_keuntungan"
										type="number"
										placeholder="Masukkan Nominal Keuntungan / Profit"
										// required
										defaultValue={this.state.detailPostpaid.nominal_keuntungan}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>

			</div>
		);
	}
}

export default PostPaid;
