import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col,  Nav,
NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from 'reactdatepicker';

// NUmber FOrmat
import NumberFormat from 'react-number-format';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: '',
	username: '',
	password: '',
	nik: 0,
	id_role: '',
	status: null,
	no_hp: null,
	email: null
};


var moment = require('moment');

class TopUp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data_proses: [],
			data_sudah_bayar: [],
			data_selesai: [],
			data_tolak: [],
			data_detail: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				formVerifikasi: false,
				statGet: false,
				formReset: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			statKonfirmasi: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			pendStat: [],
			frmPen: [],
			jpStat: [],
			frmJp: [],
			formPenduduk: 'none',
			dataVerifikasi: [],
			photoIndex: 0,
			isOpen: false,
			idVerifikasi: null,
			dataReset: {
				uuid: '',
				username: ''
			},
			jenisUser: [],
			id_jenisUser: null,
			tingkatUser: [],
			id_tingkatUser: null,

			// Tab Pane
			active_tab_icon: '1',

			// Loading
			loading: true,
		};
	}


	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: 'Proses' });
					break;

				case '2':
					this.fetch({ page: null, where: [], tab: 'Sudah Bayar' });
					break;

				case '3':
					this.fetch({ page: null, where: [], tab: 'Selesai' });
					break;

				case '4':
					this.fetch({ page: null, where: [], tab: 'Tolak' });
					break;

				default:
					this.fetch({ page: null, where: [], tab: 'Proses' });
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// Bootstrap tabs function
	// toggle_icon(tab) {
	// 	if (this.state.active_tab_icon !== tab) {
	// 		this.setState({
	// 			active_tab_icon: tab
	// 		});
	// 	}
	// }


	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		console.log("TAB APANI")
		console.log(params.tab)
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = 'page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = 'page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = 'page=' + (parseInt(this.state.pagination.current_page) - 1);
				case '<<':
					link = 'page=1';
					break;
				default:
					link = 'page=' + params.page;
					break;
			}
		}
		// Get("topup?status="+ params.tab + link, null, dtkat => {
		let imbuh = ""
		if(link == ''){
			imbuh = ""
		}
		else{
			imbuh = "&"
		}
			
		Get("topup?status="+ params.tab + imbuh + link, null, dtkat => {
		// Get("topup"+ imbuh + link + "&status="+ params.tab, null, dtkat => {
			console.log("Data TopUp")
			console.log(dtkat)
			if(dtkat.data){
				switch (params.tab) {
					case 'Proses':
						this.setState({
							data_proses: dtkat.data.data,
							loading: false,
							pagination: dtkat.data
						});
						break;
	
					case 'Sudah Bayar':
						this.setState({
							data_sudah_bayar: dtkat.data.data,
							loading: false,
							pagination: dtkat.data
						});
						break;
	
					case 'Selesai':
						this.setState({
							data_selesai: dtkat.data.data,
							loading: false,
							pagination: dtkat.data
						});
						break;
	
					case 'Tolak':
						this.setState({
							data_tolak: dtkat.data.data,
							loading: false,
							pagination: dtkat.data
						});
						break;
				}
				// this.setState({ data: dtkat.data.data, loading: false, pagination: dtkat.data});
				this.pagination(dtkat.data, params.tab);
			}
			else{
				switch (params.tab) {
					case 'Proses':
						this.setState({
							data_proses: [],
							loading: false, 
							// pagination: dtkat.data
						});
						break;
	
					case 'Sudah Bayar':
						this.setState({
							data_sudah_bayar: [],
							loading: false, 
							// pagination: dtkat.data
						});
						break;
	
					case 'Selesai':
						this.setState({
							data_selesai: [],
							loading: false,
							// pagination: dtkat.data
						});
						break;
	
					case 'Tolak':
						this.setState({
							data_tolak: [],
							loading: false,
							// pagination: dtkat.data
						});
						break;
				}
			}
		});
	};

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i, tab: tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab: tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab: tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab: tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page, tab: tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};
	
	// Cari Data Table
	cariData = (e) => {
		if (e.key == 'Enter') {
			let addRole = {
				q: e.target.value
			};
			cekLogin('jenis-surat', addRole, (data) => {
				this.setState({
					data: data.data.results.data,
					pagination: data.data.results
				});
			});
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		if (this.state.id_tingkatUser && this.state.id_jenisUser) {
			let id_wilayah = '';
			if (this.state.id_tingkatUser.value == 'Kabupaten') {
				if (this.state.prvStat == null || this.state.kbktStat == null) {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
					});
				} else {
					id_wilayah = this.state.kbktStat.value;
				}
			} else if (this.state.id_tingkatUser.value == 'Kecamatan') {
				if (this.state.prvStat == null || this.state.kbktStat == null || this.state.kecStat == null) {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
					});
				} else {
					id_wilayah = this.state.kecStat.value;
				}
			}

			let passnya = document.getElementById('password').value;
			if (id_wilayah != '') {
				let add = {
					uuid: this.state.dt.uuid,
					username: document.getElementById('username').value,
					id_wilayah: id_wilayah,
					tingkat: this.state.id_tingkatUser.value,
					no_hp: document.getElementById('no_hp').value,
					email: document.getElementById('email').value,
					nama_lengkap: document.getElementById('nama_lengkap').value,
					nip: document.getElementById('nip').value,
					jenis: this.state.id_jenisUser.value
				};

				console.log('Data Simpan');
				console.log(add);
				let psn = '';
				let resstat = 204;
				let metode = 'create';
				if (add.uuid == '') {
					psn = 'Tambah';
					resstat = 201;
					add.uuid = null;
					add.password = passnya;
				} else {
					psn = 'Ubah';
					resstat = 200;
					metode = 'update';

					if (passnya) {
						add.password = passnya;
					}
				}

				PostStatus('pengguna/' + metode, add.uuid, add, (res) => {
					console.log(res);
					if (res === resstat) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Berhasil ' + psn + ' Data'
						});
						this.state.status.form = false;
						this.state.status.btnForm = false;
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Gagal ' + psn + ' Data'
						});
					}
					this.componentDidMount(this.state.active_tab_icon);
					this.forceUpdate();
				});
			}
		} else {
			if (this.state.id_tingkatUser == null) {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Data Tingkatan Pengguna Tidak Boleh Kosong'
				});
			} else if (this.state.id_jenisUser == null) {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Data Jenis Pengguna Tidak Boleh Kosong'
				});
			} else if (this.state.id_tingkatUser) {
				if (this.state.id_tingkatUser.value == 'Kabupaten') {
					if (this.state.prvStat == null || this.state.kbktStat == null) {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
						});
					}
				} else if (this.state.id_tingkatUser.value == 'Kecamatan') {
					if (this.state.prvStat == null || this.state.kbktStat == null || this.state.kecStat == null) {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Pengguna',
							pesanAlert: 'Data Tingkatan Pengguna Tidak Lengkap'
						});
					}
				}
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Data Pengguna Tidak Boleh Kosong'
				});
			}
		}
	};
	// ========================================================================

	// ================== simpan data ==================
	simpanReset = (id) => {
		console.log(id);
		if (id) {
			Get('pengguna/reset-password/' + id, null, (res) => {
				this.forceUpdate();
				console.log(res);
				if (res) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Berhasil reset Data'
					});
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Gagal reset Data'
					});
				}
				this.tutupForm();

				this.componentDidMount(this.state.active_tab_icon);
			});
		} else {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Data Pengguna',
				pesanAlert: 'Gagal reset Data'
			});
		}
	};
	// =
	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log('IDNYA');
		console.log(data);
		this.forceUpdate();

		console.log(data);
		this.state.dt.uuid = data.uuid;
		this.state.dt.email = data.email;
		this.state.dt.id_wilayah = data.id_wilayah;
		this.state.dt.jenis = data.jenis;
		this.state.dt.nama_lengkap = data.nama_lengkap;
		this.state.dt.nip = data.nip;
		this.state.dt.no_hp = data.no_hp;
		this.state.dt.role = data.role;
		this.state.dt.status = data.status;
		this.state.dt.tingkat = data.tingkat;
		this.state.dt.username = data.username;

		this.state.id_tingkatUser = { value: data.tingkat, label: data.tingkat };
		this.state.id_jenisUser = { value: data.jenis, label: data.jenis };

		if (data.tingkat == 'Kabupaten') {
			this.state.prvStat = {
				value: data.data_kabkota.data_provinsi.id,
				label: data.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = { value: data.data_kabkota.id, label: data.data_kabkota.nama };
		} else if (data.tingkat == 'Kecamatan') {
			this.state.prvStat = {
				value: data.data_kecamatan.data_kabkota.data_provinsi.id,
				label: data.data_kecamatan.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = {
				value: data.data_kecamatan.data_kabkota.id,
				label: data.data_kecamatan.data_kabkota.nama
			};
			this.state.kecStat = { value: data.data_kecamatan.id, label: data.data_kecamatan.nama };
		}

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};
	resetPassword = (data) => {
		console.log(data);

		this.setState({
			dataReset: data,
			status: {
				...this.state.status,
				formReset: true
			}
		});
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('pengguna/delete', id, (res) => {
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount(this.state.active_tab_icon);
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;

		this.state.status.form = false;
		this.state.status.formReset = false;
		this.state.status.formVerifikasi = false;
		this.state.statKonfirmasi=false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		console.log(row);
		let btnApproval = true;
		let btnDetail = true;
		
		let status = row.status;
		if(status=="Selesai" || status == "Tolak"){
			btnDetail = true;
			btnApproval = false;
		}
		else{
			btnDetail = false;
			btnApproval = true;
		}
		
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					{btnApproval 
					?
						(
							<Button
								size="md"
								color="primary"
								className="btn-icon"
								onClick={() => {
									this.setState({
										judul: 'Konfirmasi Top Up',
										statKonfirmasi: true,
										status: {...this.state.status, btnForm: false}
									});
									this.previewTopUp(row);
								}}
								disabled={this.state.status.btnAksi}
							>
								<i className="fa fa-check" /> Konfirmasi
							</Button>
						)
					: ''}

					{btnDetail 
					? 
						(
							<Button
								size="md"
								color="success"
								className="btn-icon"
								onClick={() => {
									this.setState({
										judul: 'Detail Top Up',
										statKonfirmasi: true
									});
									this.previewTopUp(row);
								}}
								disabled={this.state.status.btnAksi}
							>
								<i className="fa fa-eye" /> Detail
							</Button>
						) 
					: ''}
				</div>
			</>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
				case 'keldes':
					let idDus = this.state.dusStat;
					this.setState({ kelStat: e });
					if (this.state.id_kategori_penduduk.value == 2) {
						this.setState({ dusStat: idDus });
					} else {
						this.setState({ dusStat: null });
					}

					break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// console.log(this.state.rtStat)
					break;
				case 'ukuran_kertas':
					this.state.dt.id_ukuran_kertas = e.value;
					this.setState({ ukkStat: e });
					break;

				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;

				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	componentDidMount(tab = '1') {
		let tabActive = tab;

		if (typeof this.props.location.tabProps != 'undefined') {
			this.state.active_tab_icon = this.props.location.tabProps;
			tabActive = this.props.location.tabProps.toString();
		}
		if (typeof this.props.location.state != 'undefined') {
			this.state.active_tab_icon = this.props.location.state.tab;
		}


		switch (tabActive) {
			case '1':
				this.fetch({ page: null, where: [], tab: 'Proses' });
				break;

			case '2':
				this.fetch({ page: null, where: [], tab: 'Selesai' });
				break;

			case '3':
				this.fetch({ page: null, where: [], tab: 'Tolak' });
				break;

			default:
				this.fetch({ page: null, where: [], tab: 'Proses' });
				break;
		}

		this.forceUpdate();
	}

	
	previewTopUp = (data) => {
		let tglTransaksi = new Date(data.CreatedAt)
		let tglExpired = new Date(data.expired)
		
		this.state.data_detail = {
			uuid: data.uuid,
			metode: data.metode,
			nominal: data.nominal,
			status: data.status,
			pengguna: data.Pengguna.nama_lengkap,
			username: data.Pengguna.username,

			nomor_rekening: data.NomorRekeningPerusahaan.nomor_rekening,
			atas_nama: data.NomorRekeningPerusahaan.atas_nama,
			jenis: data.NomorRekeningPerusahaan.jenis,

			nomor_rekening_pengguna: data.Pengguna.NomorRekening.nomor_rekening,
			atas_nama_pengguna: data.Pengguna.NomorRekening.atas_nama,
			
			akronim_bank: data.NomorRekeningPerusahaan.Bank.akronim,
			kode_bank: data.NomorRekeningPerusahaan.Bank.kode_bank,
			nama_bank: data.NomorRekeningPerusahaan.Bank.nama,

			tgl_transaksi: tglTransaksi.toLocaleDateString('id-ID') + ' ' + tglTransaksi.getHours() + ':' + tglTransaksi.getMinutes() + ':' + tglTransaksi.getSeconds(),
			tgl_expired: tglExpired.toLocaleDateString('id-ID') + ' ' + tglExpired.getHours() + ':' + tglExpired.getMinutes() + ':' + tglExpired.getSeconds(),
		}

		this.forceUpdate()
	}

	konfirmasiTopUp = (id, status) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Ya, saya yakin"
					confirmBtnBsStyle="warning"
					confirmBtnStyle={{ fontWeight: 600 }}
					cancelBtnText="Batal"
					cancelBtnBsStyle="default"
					cancelBtnStyle={{ fontWeight: 600 }}
					type="warning"
					title={status=="Tolak"?"Apakah anda yakin ingin tolak proses top up ini ?":"Apakah anda yakin ingin setujui proses top up ini "}
					onCancel={() => {
						this.setState({ alert: null });
					}}
					disabled={this.state.status.btnForm}
					onConfirm={() => {
							if (document.getElementById('nominal_topup').value == '') {
								this.setState({
									show: true,
									basicType: 'warning',
									basicTitle: 'Konfirmasi Top Up',
									pesanAlert: 'Isi nominal transaksi terlebih dahulu'
								});
							} else {
								this.setState({ 
									alert: null,
									status: {...this.state.status, btnForm: true}
								});

								let nominal_verifikasi = parseInt(document.getElementById('nominal_topup').value.replace(/[^\d]/g, ''))

								let bodyFormData = {
									uuid: id,
									status: status,
									nominal_verifikasi,
								};
	
								let psn_bos = ""
								if(status=="Tolak"){
									psn_bos = "tolak proses"
								}
								else{
									psn_bos = "setujui proses"
								}
	
								Post('topup', bodyFormData.uuid, bodyFormData, (data) => {
									if (data.status == 200) {
										this.setState({ alert: null });
										this.setState({ 
											show: true, 
											basicType:'success', 
											basicTitle:'Konfirmasi Top Up', 
											pesanAlert:'Berhasil '+ psn_bos +' top up' 
										});
										this.componentDidMount(this.state.active_tab_icon);
										this.tutupForm();
										document.getElementById('nominal_topup').value = ''
									} else {
										this.setState({ alert: null });
										this.setState({ 
											show: true, 
											basicType:'danger', 
											basicTitle:'Konfirmasi Top Up', 
											pesanAlert:'Gagal '+ psn_bos + ' top up' 
										});
									}
								});
							}
					}}
				>
					Proses ini tidak dapat dibatalkan, pastikan anda sudah teliti dalam melakukan proses konfirmasi top up <br></br>
					Nominal Top Up : {document.getElementById("nominal_topup").value}
				</SweetAlert>
			)
		});
	};

	render() {

		// console.log("Detail Data")
		// console.log(this.state.tgl_transaksi)
		// console.log(this.state.tgl_expired)
		const { photoIndex, isOpen } = this.state;

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		var data_proses = this.state.data_proses;
		var data_sudah_bayar = this.state.data_sudah_bayar;
		var data_selesai = this.state.data_selesai;
		var data_tolak = this.state.data_tolak;

		// var data = require('../assets/json/company');

		const frmDef = {
			uuid: '',
			username: '',
			password: '',
			nik: '',
			id_role: '',
			status: null,
			no_hp: null,
			email: null
		};
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'CreatedAt',
				text: 'Tanggal',
				sort: true,
				formatter: (cell, obj) => {
					let date = moment(new Date(obj.CreatedAt))
					return date.format("YYYY-MM-DD HH:mm:ss")
				}
			},
			{
				dataField: 'Pengguna.nama_lengkap',
				text: 'Nama Pengguna',
				sort: true
			},
			{
				dataField: 'metode',
				text: 'Metode',
				sort: true
			},

			{
				dataField: 'nominal',
				text: 'Nominal Top Up',
				sort: true,
				formatter: (cell, obj) => {
					return 'Rp.' + new Intl.NumberFormat(['ban', 'id']).format(obj.nominal)
				}
			},
			{
				dataField: 'nominal_verifikasi',
				text: 'Nominal Terverifikasi',
				sort: true,
				formatter: (cell, obj) => {
					return 'Rp.' + new Intl.NumberFormat(['ban', 'id']).format(obj.nominal_verifikasi)
				}
			},
			{
				dataField: 'status',
				text: 'Status',
				sort: true
			},
			// {
			//   dataField: "tingkat",
			//   text: "Tingkat",
			//   sort: true
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<Nav tabs className="tabs-color">
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '1'
												})}
												onClick={() => {
													this.toggle_icon('1');
												}}
											>
												<i className="icofont icofont-ui-home" />
												Proses
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '2'
												})}
												onClick={() => {
													this.toggle_icon('2');
												}}
											>
												<i className="icofont icofont-ui-home" />
												Sudah Bayar
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '3'
												})}
												onClick={() => {
													this.toggle_icon('3');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />
												Selesai
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '4'
												})}
												onClick={() => {
													this.toggle_icon('4');
												}}
											>
												<i className="icofont icofont-man-in-glasses" />
												Tolak
											</NavLink>
										</NavItem>
									</Nav>
									
									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row" style={{ flexDirection: "row-reverse" }}>
															{/* <div className="col-lg-6 text-right">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	style={{ marginBottom: '15px' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Top Up',
																			dt: frmDef,
																		});
																		this.state.dt.uuid = null;
																		this.forceUpdate();
																		this.bukaForm();
																	}}
																>
																	Tambah BUMDES
																</Button>
															</div> */}
															<div className="col-sm-6">
																&nbsp;
															</div>
															<div className="col-sm-6">
																{/* <input
																	type="text"
																	className="form-control"
																	id="cariTable"
																	placeholder="Cari Disini"
																	onKeyPress={this.cariData}
																	style={{ marginBottom: '15px' }}
																/> */}
															</div>
															
														</div>
														{ this.state.loading 
															? 
																<div align="center">
																	<br/><br/>
																	<img 
																	src={require("../assets/images/loading-bos.gif")} 
																	style={{
																		width: "100%",
																		borderRadius: "10px",
																		width: '70px'
																	}}/> 
																</div>
															:
																<>
																<BootstrapTable keyField="id" data={data_proses} columns={columns} />
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
																</>
														}
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row" style={{ flexDirection: "row-reverse" }}>
															{/* <div className="col-lg-6 text-right">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	style={{ marginBottom: '15px' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Top Up',
																			dt: frmDef,
																		});
																		this.state.dt.uuid = null;
																		this.forceUpdate();
																		this.bukaForm();
																	}}
																>
																	Tambah BUMDES
																</Button>
															</div> */}
															<div className="col-sm-6">
																&nbsp;
															</div>
															<div className="col-sm-6">
																{/* <input
																	type="text"
																	className="form-control"
																	id="cariTable"
																	placeholder="Cari Disini"
																	onKeyPress={this.cariData}
																	style={{ marginBottom: '15px' }}
																/> */}
															</div>
															
														</div>
														{ this.state.loading 
															? 
																<div align="center">
																	<br/><br/>
																	<img 
																	src={require("../assets/images/loading-bos.gif")} 
																	style={{
																		width: "100%",
																		borderRadius: "10px",
																		width: '70px'
																	}}/> 
																</div>
															:
																<>
																<BootstrapTable keyField="id" data={data_sudah_bayar} columns={columns} />
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
																</>
														}
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="3">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row" style={{ flexDirection: "row-reverse" }}>
															{/* <div className="col-lg-6 text-right">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	style={{ marginBottom: '15px' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Top Up',
																			dt: frmDef,
																		});
																		this.state.dt.uuid = null;
																		this.forceUpdate();
																		this.bukaForm();
																	}}
																>
																	Tambah BUMDES
																</Button>
															</div> */}
															<div className="col-sm-6">
																&nbsp;
															</div>
															<div className="col-sm-6">
																{/* <input
																	type="text"
																	className="form-control"
																	id="cariTable"
																	placeholder="Cari Disini"
																	onKeyPress={this.cariData}
																	style={{ marginBottom: '15px' }}
																/> */}
															</div>
														</div>
														{ this.state.loading 
															? 
																<div align="center">
																	<br/><br/>
																	<img 
																	src={require("../assets/images/loading-bos.gif")} 
																	style={{
																		width: "100%",
																		borderRadius: "10px",
																		width: '70px'
																	}}/> 
																</div>
															:
																<>
																<BootstrapTable keyField="id" data={data_selesai} columns={columns} />
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
																</>
														}
													</Col>
												</Row>
											</div>
										</TabPane>
										<TabPane tabId="4">
											<div style={{ marginTop: 15 }}>
												<Row>
													<Col sm="12">
														<div className="row" style={{ flexDirection: "row-reverse" }}>
															{/* <div className="col-lg-6 text-right">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	style={{ marginBottom: '15px' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Top Up',
																			dt: frmDef,
																		});
																		this.state.dt.uuid = null;
																		this.forceUpdate();
																		this.bukaForm();
																	}}
																>
																	Tambah BUMDES
																</Button>
															</div> */}
															<div className="col-sm-6">
																&nbsp;
															</div>
															<div className="col-sm-6">
																{/* <input
																	type="text"
																	className="form-control"
																	id="cariTable"
																	placeholder="Cari Disini"
																	onKeyPress={this.cariData}
																	style={{ marginBottom: '15px' }}
																/> */}
															</div>
														</div>
														{ this.state.loading 
															? 
																<div align="center">
																	<br/><br/>
																	<img 
																	src={require("../assets/images/loading-bos.gif")} 
																	style={{
																		width: "100%",
																		borderRadius: "10px",
																		width: '70px'
																	}}/> 
																</div>
															:
																<>
																<BootstrapTable keyField="id" data={data_tolak} columns={columns} />
																<div className="pull-right text-white">
																	{this.state.awal}
																	{this.state.sebelum}
																	{this.state.hal.map((dt) => {
																		return dt;
																	})}
																	{this.state.setelah}
																	{this.state.akhir}
																</div>
																</>
														}
													</Col>
												</Row>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					size="lg"
					open={this.state.statKonfirmasi}
					onClose={this.tutupForm}
					styles={{ modal: { width: '60%' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form">
						<div size="lg" className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-md-6">
									<div className="col-md-12">
										<b>Tanggal Transaksi</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.tgl_transaksi}
									</div>
								</div>
								<div className="col-md-6">
									<div className="col-md-12">
										<b>Tanggal Expired</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.tgl_expired}
									</div>
									
								</div>
							</div>

							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Username</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.username}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Metode</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.metode}
									</div>
									
								</div>
							</div>

							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Nama Pengguna</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.pengguna}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Bank Tujuan</b>
									</div>
									<div className="col-md-12">
										({this.state.data_detail.kode_bank} - {this.state.data_detail.akronim_bank}) {this.state.data_detail.nama_bank}
									</div>
									
								</div>
							</div>
							
							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Nomor Rekening Pengguna</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.nomor_rekening_pengguna}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Nomor Rekening Tujuan</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.nomor_rekening}
									</div>
									
								</div>
							</div>

							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Atas Nama</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.atas_nama_pengguna}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Atas Nama Bank Tujuan</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.atas_nama}
									</div>
								</div>
							</div>

							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Status</b>
									</div>
									<div className="col-md-12">
										{this.state.data_detail.status}
									</div>
								</div>
								<div className="col-sm-6">
									<div className="col-md-12">
										<b>Nominal</b>
									</div>
									<div className="col-md-12">
									Rp. {new Intl.NumberFormat(['ban', 'id']).format(this.state.data_detail.nominal)}
									</div>
								</div>
							</div>

							<div className="row" style={{ marginBottom: '10px' }}>
								<div className="col-sm-12">
									<div className="col-md-12">
										<b>Nominal Top Up</b>
									</div>
									<div className="col-md-12">
										<NumberFormat
											id="nominal_topup"
											placeholder="Contoh : 100.000"
											thousandSeparator={true}
											prefix={'Rp. '}
											className="form-control"
											defaultValue={this.state.data_detail.nominal}
											required
										/>
									</div>
								</div>
							</div>

						</div>

						<div className="modal-footer" style={{ justifyContent: "center" }}>
							<div className="row" style={{ width: "100%" }}>
									{
										this.state.data_detail.status == "Selesai" || this.state.data_detail.status == "Tolak" ?
											<>
												<div className="col-md-12" style={{ textAlign: 'right' }}>
													<button
														type="button"
														className="btn btn-warning"
														// disabled={this.state.status.btnForm}
														onClick={this.tutupForm}
													>
														Tutup
													</button>{' '}
												</div>
											</>
										:
											<>
												<div className="col-md-6" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
													<button
														type="button"
														className="btn btn-danger"
														// onClick={() => {
														// 	this.simpanReset(this.state.dataReset.uuid);
														// }}
														onClick={(e) => {
															e.preventDefault();
															this.konfirmasiTopUp(this.state.data_detail.uuid, 'Tolak');
														}}
														disabled={this.state.status.btnForm}
													>
														Tolak
													</button>
													&nbsp;&nbsp;&nbsp;
													<button
														type="button"
														className="btn btn-success"
														// onClick={() => {
														// 	this.simpanReset(this.state.dataReset.uuid);
														// }}
														onClick={(e) => {
															e.preventDefault();
															this.konfirmasiTopUp(this.state.data_detail.uuid,'Selesai');
														}}
														disabled={this.state.status.btnForm}
													>
														Setuju
													</button>
												</div>

												<div className="col-md-6" style={{ textAlign: 'right' }}>
													<button
														type="button"
														className="btn btn-warning"
														disabled={this.state.status.btnForm}
														onClick={this.tutupForm}
													>
														Tutup
													</button>{' '}
												</div>
											</>
									}
							</div>
						</div>
					</Form>
				</Modal>



				<Modal
					size="lg"
					open={this.state.status.form}
					onClose={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div size="lg" className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
							/>

							{/* <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                    Tingkatan User
									</Label>
                  <Col sm="9">
                    <div className="row">
                      <div className="col-sm-11">
                        <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={e => {
                            this.changeSelectValue(e, "tingkatUser");
                          }}
                          defaultValue={this.state.id_tingkatUser}
                          value={this.state.id_tingkatUser}
                          name="tingkatan_user"
                          options={this.state.tingkatUser}
                          placeholder="Pilih Tingkatan Pengguna"
                          isClearable
                        />
                      </div>
                    </div>
                  </Col>
                </FormGroup> */}

							{this.state.id_tingkatUser && this.state.id_tingkatUser.value == 'Kabupaten' ? (
								<div>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Provinsi
										</Label>
										<Col sm="9">
											<div className="row">
												<div className="col-sm-11">
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.getChild(e, 'provinsi', 'prov', 'kab-kota');
														}}
														defaultValue={this.state.prvStat}
														value={this.state.prvStat}
														name="provinsi"
														options={this.state.frmPrv}
														placeholder="Pilih Provinsi"
														isClearable
													/>
												</div>
											</div>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Kabupaten / Kota
										</Label>
										<Col sm="9">
											<div className="row">
												<div className="col-sm-11">
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
														}}
														defaultValue={this.state.kbktStat}
														value={this.state.kbktStat}
														name="kabkota"
														options={this.state.frmKbkt}
														placeholder="Pilih Kabupaten/Kota"
														isClearable
													/>
												</div>
											</div>
										</Col>
									</FormGroup>
								</div>
							) : this.state.id_tingkatUser && this.state.id_tingkatUser.value == 'Kecamatan' ? (
								<div>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Provinsi
										</Label>
										<Col sm="9">
											<div className="row">
												<div className="col-sm-11">
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.getChild(e, 'provinsi', 'prov', 'kab-kota');
														}}
														defaultValue={this.state.prvStat}
														value={this.state.prvStat}
														name="provinsi"
														options={this.state.frmPrv}
														placeholder="Pilih Provinsi"
														isClearable
													/>
												</div>
											</div>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Kabupaten / Kota
										</Label>
										<Col sm="9">
											<div className="row">
												<div className="col-sm-11">
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
														}}
														defaultValue={this.state.kbktStat}
														value={this.state.kbktStat}
														name="kabkota"
														options={this.state.frmKbkt}
														placeholder="Pilih Kabupaten/Kota"
														isClearable
													/>
												</div>
											</div>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Kecamatan
										</Label>
										<Col sm="9">
											<div className="row">
												<div className="col-sm-11">
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.getChild(e, 'kecamatan', 'kec', 'keldes');
														}}
														defaultValue={this.state.kecStat}
														value={this.state.kecStat}
														name="kecamatan"
														options={this.state.frmKec}
														placeholder="Pilih Kecamatan"
														isClearable
													/>
												</div>
											</div>
										</Col>
									</FormGroup>
								</div>
							) : (
								''
							)}

							{/* <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis Pengguna
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "jenisUser");
                    }}
                    defaultValue={this.state.id_jenisUser}
                    value={this.state.id_jenisUser}
                    name="jenis_user"
                    options={this.state.jenisUser}
                    placeholder="Pilih Jenis Pengguna"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama BUMDES
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_lengkap"
										type="text"
										placeholder="Masukkan Nama BUMDES"
										required
										defaultValue={this.state.dt.nama_lengkap}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Provinsi
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'provinsi', 'prov', 'kab-kota');
										}}
										defaultValue={this.state.prvStat}
										value={this.state.prvStat}
										name="provinsi"
										options={this.state.frmPrv}
										placeholder="Pilih Provinsi"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kabupaten / Kota
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
										}}
										defaultValue={this.state.kbktStat}
										value={this.state.kbktStat}
										name="kabkota"
										options={this.state.frmKbkt}
										placeholder="Pilih Kabupaten/Kota"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kecamatan
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kecamatan', 'kec', 'keldes');
										}}
										defaultValue={this.state.kecStat}
										value={this.state.kecStat}
										name="kecamatan"
										options={this.state.frmKec}
										placeholder="Pilih Kecamatan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kelurahan / {JENIS_DESA}
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'keldes');
										}}
										defaultValue={this.state.kldsStat}
										value={this.state.kldsStat}
										name="keldes"
										options={this.state.frmKlds}
										placeholder={'Pilih Kelurahan/' + JENIS_DESA}
										isClearable
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Jumlah Top Up
								</Label>
								<Col sm="9">
									<NumberFormat
										id="jumlah"
										placeholder="Contoh : 100.000"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										// onFocus={() => this.setActiveInput('penghasilan')}
										// value={this.state.input['penghasilan']}
										// onChange={(e) => this.onChangeInput(e)}
									/>
									{/* <input
										className="form-control"
										id="no_hp"
										type="number"
										placeholder="Masukkan Jumlah Top Up"
										required
										defaultValue={this.state.dt.no_hp}
									/> */}
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default TopUp;