import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "./index.scss";

// ** Import custom components **
import store from "./store";
import App from "./components/app";
// import ChartWidget from './components/widget/chart-widget';

// Custom
// import Operator from './feature/operator';
// import Role from './feature/role';
import Login from "./feature/login";
// import Register from './feature/register';
// import ForgetPassword from './feature/forget-password';
// import ResetPassword from './feature/reset-password';
// import Profile from './feature/profile';
// import PengaturanAplikasi from './feature/pengaturan-aplikasi';

// // // Dashboards
import Dashboard from "./feature/dashboard";
import Bumdes from "./feature/bumdes";
import TopUp from "./feature/topup";
import Banner from "./feature/banner";
import AgenBumdes from "./feature/agen";
import Transaksi from "./feature/transaksi";
import Pengguna from "./feature/pengguna";
import Bank from "./feature/bank";
import Rekening from "./feature/rekening";
import PrePaid from "./feature/prepaid";
import PostPaid from "./feature/postpaid";
import Campaign from "./feature/campaign";
import RekapProfit from "./feature/rekap-profit";
import PengembalianDana from "./feature/pengembalian-dana";
import PencairanDana from "./feature/pencairan-dana";

// Surat
// import Suratpindahnikah from './feature/suratpindahnikah';

// import { DataToken } from './function/Format';

// private route n public route
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (sessionStorage.getItem("access_token")) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
);

// Route untuk public, tidak perlu login bisa akses route ini
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (sessionStorage.getItem("access_token")) {
        // let token = DataToken(sessionStorage.getItem('access_token'));
        // if (token.sub.data_role.nama_role === 'Admin KiosK') {
        // }
        return (
          <Redirect
            to={{
              pathname: "/admin/dashboard",
              state: { from: props.location },
            }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              <PublicRoute
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={Login}
              />

              <PublicRoute
                path={`${process.env.PUBLIC_URL}/login`}
                component={Login}
              />

              <App>
                {/* {
									<PrivateRoute
										path={`${process.env.PUBLIC_URL}/dashboard/default`}
										component={Default}
									/>
								} */}

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/dashboard`}
                  component={Dashboard}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/bumdes`}
                  component={Bumdes}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/topup`}
                  component={TopUp}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/banner`}
                  component={Banner}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/prepaid`}
                  component={PrePaid}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/postpaid`}
                  component={PostPaid}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/agen`}
                  component={AgenBumdes}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/transaksi`}
                  component={Transaksi}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/pengguna`}
                  component={Pengguna}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/bank`}
                  component={Bank}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/rekening`}
                  component={Rekening}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/campaign`}
                  component={Campaign}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/rekap-profit`}
                  component={RekapProfit}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/pengembalian-dana`}
                  component={PengembalianDana}
                />
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/admin/pencairan-dana`}
                  component={PencairanDana}
                />

								{/* <SuperRoute
									path={`${process.env.PUBLIC_URL}/admin/pengaturan-aplikasi`}
									component={PengaturanAplikasi}
								/>  */}
              </App>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
