import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';

import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	TabContent,
	TabPane,
	NavItem,
	NavLink
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import Datetime from 'react-datetime';

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datetime/css/react-datetime.css";
import classnames from 'classnames';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: '',
	username: '',
	password: '',
	nik: 0,
	id_role: '',
	nama_lengkap: '',
	alamat: '',
	status: null,
	no_hp: null,
	email: null
};

const moment = require('moment');
const dateFormat = require('dateformat');

class Transaksi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dataPending: [],
			dataGagal: [],
			dataSelesai: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				statGet: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			active_tab_icon: '1',
			tanggal_mulai: null,
			tanggal_selesai: new Date,
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			pendStat: [],
			frmPen: [],
			jpStat: [],
			frmJp: [],
			formPenduduk: 'none',
			dataVerifikasi: [],
			photoIndex: 0,
			isOpen: false,
			idVerifikasi: null,
			dataReset: {
				uuid: '',
				username: ''
			},
			jenisUser: [],
			id_jenisUser: null,
			tingkatUser: [],
			id_tingkatUser: null,

			// Loading
			loading: true
		};
	}

	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		let extension = ''

		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}

		let status = 'Proses'

		switch (params.tab) {
			case '1':
				status = 'Proses'
				break;
			case '2':
				status = 'Pending'
				break;
			case '3':
				status = 'Gagal'
				break;
			case '4':
				status = 'Selesai'
				break;
		
			default:
				break;
		}

		if (params.extension) {
			extension = params.extension
		}

		Get('transaksi?status=' + status + extension + link, null, (dtkat) => {
			// console.log("Data Transaksi")
			console.log(dtkat)
			if (dtkat.data) {
				// this.setState({ data: dtkat.data.data, loading: false, pagination: dtkat.data });

				let listData = []
				if (dtkat.data.data) {
					listData = dtkat.data.data
				}

				switch (params.tab) {
					case '1':
						this.setState({ 
							data: listData, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '2':
						this.setState({ 
							dataPending: listData, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '3':
						this.setState({ 
							dataGagal: listData, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '4':
						this.setState({ 
							dataSelesai: listData, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				}

				this.pagination(dtkat.data, params.tab);
			} else {
				switch (params.tab) {
					case '1':
						this.setState({ 
							data: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '2':
						this.setState({ 
							dataPending: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '3':
						this.setState({ 
							dataGagal: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '4':
						this.setState({ 
							dataSelesai: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				}
			}
		});
	};

	// Pagination
	pagination = (data, tab) => {
		// const data = this.state.pagination;
		console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i, tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};
	// Cari Data Table
	cariData = (e) => {
		if (e.key == 'Enter') {
			this.state.loading = true;
			let addRole = {
				q: e.target.value
			};
			cekLogin('jenis-surat', addRole, (data) => {
				if (data.data) {
					this.setState({
						data: data.data.results.data,
						loading: false,
						pagination: data.data.results
					});
				} else {
					this.setState({
						data: [],
						loading: false,
						pagination: data.data.result
					});
				}
			});
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		if (this.state.kelStat) {
		} else {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data BUMDES',
				pesanAlert: 'Pilih Kelurahan / ' + { JENIS_DESA } + ' terlebih dahulu.'
			});
		}
	};
	// ========================================================================

	// ================== simpan data ==================
	simpanReset = (id) => {
		console.log(id);
		if (id) {
			Get('pengguna/reset-password/' + id, null, (res) => {
				this.forceUpdate();
				console.log(res);
				if (res) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Berhasil reset Data'
					});
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Gagal reset Data'
					});
				}
				this.tutupForm();

				this.componentDidMount();
			});
		} else {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Data Pengguna',
				pesanAlert: 'Gagal reset Data'
			});
		}
	};
	// =
	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log('IDNYA');
		console.log(data);
		this.forceUpdate();

		console.log(data);
		this.state.dt.uuid = data.uuid;
		this.state.dt.email = data.email;
		this.state.dt.id_wilayah = data.id_wilayah;
		this.state.dt.jenis = data.jenis;
		this.state.dt.nama_lengkap = data.nama_lengkap;
		this.state.dt.nip = data.nip;
		this.state.dt.no_hp = data.no_hp;
		this.state.dt.role = data.role;
		this.state.dt.status = data.status;
		this.state.dt.tingkat = data.tingkat;
		this.state.dt.username = data.username;

		this.state.id_tingkatUser = { value: data.tingkat, label: data.tingkat };
		this.state.id_jenisUser = { value: data.jenis, label: data.jenis };

		if (data.tingkat == 'Kabupaten') {
			this.state.prvStat = {
				value: data.data_kabkota.data_provinsi.id,
				label: data.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = { value: data.data_kabkota.id, label: data.data_kabkota.nama };
		} else if (data.tingkat == 'Kecamatan') {
			this.state.prvStat = {
				value: data.data_kecamatan.data_kabkota.data_provinsi.id,
				label: data.data_kecamatan.data_kabkota.data_provinsi.nama
			};
			this.state.kbktStat = {
				value: data.data_kecamatan.data_kabkota.id,
				label: data.data_kecamatan.data_kabkota.nama
			};
			this.state.kecStat = { value: data.data_kecamatan.id, label: data.data_kecamatan.nama };
		}

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('pengguna/delete', id, (res) => {
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Pengguna',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.kelStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;

		this.state.status.form = false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		console.log(row);
		return (
			<div>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: 'Ubah Perangkat',
							statUbah: false
						});
						this.ubahData(row);
					}}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>{' '}
				<Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => this.konfirmHapus(row.uuid)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button>
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
				case 'keldes':
					// let idDus = this.state.dusStat;
					// this.setState({ kelStat: e });
					// if (this.state.id_kategori_penduduk.value == 2) {
					// 	this.setState({ dusStat: idDus });
					// } else {
					// 	this.setState({ dusStat: null });
					// }
					this.setState({ kelStat: e });
					this.setState({ dusStat: null });

					break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// console.log(this.state.rtStat)
					break;
				case 'ukuran_kertas':
					this.state.dt.id_ukuran_kertas = e.value;
					this.setState({ ukkStat: e });
					break;

				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;

				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			// this.componentDidMount(tab)

			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: '1' })
					break;
				case '2':
					this.fetch({ page: null, where: [], tab: '2' })
					break;
				case '3':
					this.fetch({ page: null, where: [], tab: '3' })
					break;
				case '4':
					this.fetch({ page: null, where: [], tab: '4' })
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	filterData(type, moment) {
		let extension = ''
		let tgl_mulai = ''
		let tgl_selesai = ''

		// Jika set tanggal mulai
		if (type == 'mulai') {
			let tanggal_mulai = new Date(moment)
			tgl_mulai = dateFormat(tanggal_mulai, 'yyyy-mm-dd')

			if (this.state.tanggal_selesai) {
				tgl_selesai = dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd')
			}

			this.setState({ tanggal_mulai })
		} else { // Jika set tanggal selesai
			let tanggal_selesai = new Date(moment)

			if (this.state.tanggal_mulai) {
				tgl_mulai = dateFormat(this.state.tanggal_mulai, 'yyyy-mm-dd')
			}

			tgl_selesai = dateFormat(tanggal_selesai, 'yyyy-mm-dd')
			this.setState({ tanggal_selesai })
		}

		// if (this.state.tanggal_selesai) {
		// }
		// if (this.state.tanggal_selesai) {
		// 	tanggal_selesai = dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd')
		// }

		extension = '&date_start=' + tgl_mulai + ' 00:00:00' + '&date_end=' + tgl_selesai + ' 23:59:59'

		this.fetch({ 
			tab: this.state.active_tab_icon,
			extension
		});

	}

	componentDidMount(tab = '1') {
		this.fetch({ page: null, where: [], tab });

		// Get("provinsi/list", null, data => {
		// 	console.log(data)
		//   let frmPrv = [];
		//   data.results.map(dt => {
		//     frmPrv.push({ value: dt.id, label: dt.nama });
		//   });
		//   this.setState({ frmPrv });
		// });
		// Get("select-enum/pengguna/jenis", null, data => {
		//   console.log("Data Jenis User")
		//   console.log(data)
		//   let jenisUser = [];
		//   data.map(dt => {
		//     jenisUser.push({ value: dt, label: dt });
		//   });
		//   this.setState({ jenisUser });
		// });
		// Get("select-enum/pengguna/tingkat", null, data => {
		//   console.log("Data Tingkatan User")
		//   console.log(data)
		//   let tingkatUser = [];
		//   data.map(dt => {
		//     tingkatUser.push({ value: dt, label: dt });
		//   });
		//   this.setState({ tingkatUser });
		// });
	}

	render() {
		var data = this.state.data;

		switch (this.state.active_tab_icon) {
			case '1':
				data = this.state.data;
				break;

			case '2':
				data = this.state.dataPending;
				break;

			case '3':
				data = this.state.dataGagal;
				break;

			case '4':
				data = this.state.dataSelesai;
				break;
		}

		const columns = [
			{
				dataField: 'CreatedAt',
				text: 'Tanggal',
				sort: true,
				formatter: (cell, obj) => {
					let date = moment(new Date(obj.CreatedAt))
					return date.format("YYYY-MM-DD HH:mm:ss")
				}
			},
			{
				dataField: 'Pengguna.nama_lengkap',
				text: 'Nama Pengguna',
				sort: true
			},
			{
				dataField: 'nominal',
				text: 'Nominal',
				sort: true
			},
			{
				dataField: 'jenis_transaksi',
				text: 'Jenis Transaksi',
				sort: true
			},
			{
				dataField: 'status',
				text: 'Status',
				sort: true
			},
			{
				dataField: 'keterangan',
				text: 'Keterangan',
				sort: true
			}
			// {
			// 	dataField: 'aksi',
			// 	text: 'Aksi',
			// 	isDummyField: true,
			// 	csvExport: false,
			// 	formatter: this.tombolAksi
			// }
		];

		let bootstrapTable = (
			<div>
				{this.state.loading ? (
					<div align="center">
						<br/><br/>
						<img
							src={require('../assets/images/loading-bos.gif')}
							style={{
								width: '100%',
								borderRadius: '10px',
								width: '70px'
							}}
						/>
					</div>
				) : (
					<>
						<BootstrapTable keyField="id" data={data} columns={columns} />
						{data.length > 0 ? (
							<div className="pull-right text-white">
								{this.state.awal}
								{this.state.sebelum}
								{this.state.hal.map((dt) => {
									return dt;
								})}
								{this.state.setelah}
								{this.state.akhir}
							</div>
						) : ''}
					</>
				)}
			</div>
		);

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<Nav tabs className={'tabs-color'}>
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '1'
												})}
												onClick={() => {
													this.toggle_icon('1');
												}}
											>
												{/* <i className="icofont icofont-ui-home"> </i>Transaksi Proses */}
												<i className="icofont icofont-ui-home"> </i>Proses
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor : "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '2'
                        })}
                        onClick={() => {
                          this.toggle_icon('2');
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />Pending
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor : "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '3'
                        })}
                        onClick={() => {
                          this.toggle_icon('3');
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />Gagal
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor : "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '4'
                        })}
                        onClick={() => {
                          this.toggle_icon('4');
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />Selesai
                      </NavLink>
                    </NavItem>
									</Nav>
									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											{this.state.loading ? (
												<div align="center">
													<br/><br/>
													<img
														src={require('../assets/images/loading-bos.gif')}
														style={{
															width: '100%',
															borderRadius: '10px',
															width: '70px'
														}}
													/>
												</div>
											) : (
											<div style={{ padding: 15 }}>
												<div className="row" style={{ marginBottom: 5 }}>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_mulai}
															value={this.state.tanggal_mulai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_mulai: new Date(val) })
																this.filterData('mulai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal mulai'
															}}
														/>
													</div>
													<div className="col-sm-2" style={{ paddingTop: 10, paddingBottom: 10 }}>
														<h6 style={{ textAlign: "center" }}>Sampai</h6>
													</div>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_selesai}
															value={this.state.tanggal_selesai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_selesai: new Date(val) })
																this.filterData('selesai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal selesai'
															}}
														/>
													</div>
													<div className="col-lg-2">
														<Button
															className="btn btn-danger btn-success btn-md"
															onClick={(val) => {
																
																switch (this.state.active_tab_icon) {
																	case '1':
																		this.setState({ 
																			loading: true,
																			data: [],
																		})
																		break;
																	case '2':
																		this.setState({ 
																			loading: true,
																			dataPending: [],
																		})
																		break;
																	case '3':
																		this.setState({ 
																			loading: true,
																			dataGagal: [],
																		})
																		break;
																	case '4':
																		this.setState({ 
																			loading: true,
																			dataSelesai: [],
																		})
																		break;
																
																	default:
																		break;
																}

																this.fetch({ tab: this.state.active_tab_icon })

																setTimeout(function () {
																	this.setState({ 
																		tanggal_mulai: null,
																		tanggal_selesai: new Date(),
																		loading: false
																	})
																}.bind(this), 1)
															}}
														>
															Reset tanggal
														</Button>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-12">
														{this.state.active_tab_icon == '1' ? bootstrapTable : ''}
													</div>
												</div>
											</div>
											)}

										</TabPane>
										<TabPane tabId="2">
											<div style={{ padding: 15 }}>
											<div className="row" style={{ marginBottom: 5 }}>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_mulai}
															value={this.state.tanggal_mulai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_mulai: new Date(val) })
																this.filterData('mulai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal mulai'
															}}
														/>
													</div>
													<div className="col-sm-2" style={{ paddingTop: 10, paddingBottom: 10 }}>
														<h6 style={{ textAlign: "center" }}>Sampai</h6>
													</div>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_selesai}
															value={this.state.tanggal_selesai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_selesai: new Date(val) })
																this.filterData('selesai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal selesai'
															}}
														/>
													</div>
													<div className="col-lg-2">
														<Button
															className="btn btn-danger btn-success btn-md"
															onClick={(val) => {
																
																switch (this.state.active_tab_icon) {
																	case '1':
																		this.setState({ 
																			loading: true,
																			data: [],
																		})
																		break;
																	case '2':
																		this.setState({ 
																			loading: true,
																			dataPending: [],
																		})
																		break;
																	case '3':
																		this.setState({ 
																			loading: true,
																			dataGagal: [],
																		})
																		break;
																	case '4':
																		this.setState({ 
																			loading: true,
																			dataSelesai: [],
																		})
																		break;
																
																	default:
																		break;
																}

																this.fetch({ tab: this.state.active_tab_icon })

																setTimeout(function () {
																	this.setState({ 
																		tanggal_mulai: null,
																		tanggal_selesai: new Date(),
																		loading: false
																	})
																}.bind(this), 1)
															}}
														>
															Reset tanggal
														</Button>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-12">
														{this.state.active_tab_icon == '2' ? bootstrapTable : ''}
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId="3">
											<div style={{ padding: 15 }}>
											<div className="row" style={{ marginBottom: 5 }}>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_mulai}
															value={this.state.tanggal_mulai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_mulai: new Date(val) })
																this.filterData('mulai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal mulai'
															}}
														/>
													</div>
													<div className="col-sm-2" style={{ paddingTop: 10, paddingBottom: 10 }}>
														<h6 style={{ textAlign: "center" }}>Sampai</h6>
													</div>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_selesai}
															value={this.state.tanggal_selesai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_selesai: new Date(val) })
																this.filterData('selesai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal selesai'
															}}
														/>
													</div>
													<div className="col-lg-2">
														<Button
															className="btn btn-danger btn-success btn-md"
															onClick={(val) => {
																
																switch (this.state.active_tab_icon) {
																	case '1':
																		this.setState({ 
																			loading: true,
																			data: [],
																		})
																		break;
																	case '2':
																		this.setState({ 
																			loading: true,
																			dataPending: [],
																		})
																		break;
																	case '3':
																		this.setState({ 
																			loading: true,
																			dataGagal: [],
																		})
																		break;
																	case '4':
																		this.setState({ 
																			loading: true,
																			dataSelesai: [],
																		})
																		break;
																
																	default:
																		break;
																}

																this.fetch({ tab: this.state.active_tab_icon })

																setTimeout(function () {
																	this.setState({ 
																		tanggal_mulai: null,
																		tanggal_selesai: new Date(),
																		loading: false
																	})
																}.bind(this), 1)
															}}
														>
															Reset tanggal
														</Button>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-12">
														{this.state.active_tab_icon == '3' ? bootstrapTable : ''}
													</div>
												</div>
											</div>
										</TabPane>
										<TabPane tabId="4">
											<div style={{ padding: 15 }}>
											<div className="row" style={{ marginBottom: 5 }}>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_mulai}
															value={this.state.tanggal_mulai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_mulai: new Date(val) })
																this.filterData('mulai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal mulai'
															}}
														/>
													</div>
													<div className="col-sm-2" style={{ paddingTop: 10, paddingBottom: 10 }}>
														<h6 style={{ textAlign: "center" }}>Sampai</h6>
													</div>
													<div className="col-sm-4">
														<Datetime
															input={true}
															timeFormat={false}
															dateFormat="DD/MM/yyyy"
															closeOnSelect={true}
															initialValue={this.state.tanggal_selesai}
															value={this.state.tanggal_selesai}
															onChange={(val) => {
																console.log(val);
																// this.setState({ tanggal_selesai: new Date(val) })
																this.filterData('selesai', val)
															}}
															inputProps={{
																placeholder: 'Tanggal selesai'
															}}
														/>
													</div>
													<div className="col-lg-2">
														<Button
															className="btn btn-danger btn-success btn-md"
															onClick={(val) => {
																
																switch (this.state.active_tab_icon) {
																	case '1':
																		this.setState({ 
																			loading: true,
																			data: [],
																		})
																		break;
																	case '2':
																		this.setState({ 
																			loading: true,
																			dataPending: [],
																		})
																		break;
																	case '3':
																		this.setState({ 
																			loading: true,
																			dataGagal: [],
																		})
																		break;
																	case '4':
																		this.setState({ 
																			loading: true,
																			dataSelesai: [],
																		})
																		break;
																
																	default:
																		break;
																}

																this.fetch({ tab: this.state.active_tab_icon })

																setTimeout(function () {
																	this.setState({ 
																		tanggal_mulai: null,
																		tanggal_selesai: new Date(),
																		loading: false
																	})
																}.bind(this), 1)
															}}
														>
															Reset tanggal
														</Button>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-12">
														{this.state.active_tab_icon == '4' ? bootstrapTable : ''}
													</div>
												</div>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal
					size="lg"
					open={this.state.status.form}
					onClose={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div size="lg" className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama BUMDES
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_lengkap"
										type="text"
										placeholder="Masukkan Nama BUMDES"
										required
										defaultValue={this.state.dt.nama_lengkap}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Provinsi
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'provinsi', 'prov', 'kab-kota');
										}}
										defaultValue={this.state.prvStat}
										value={this.state.prvStat}
										name="provinsi"
										options={this.state.frmPrv}
										placeholder="Pilih Provinsi"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kabupaten / Kota
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
										}}
										defaultValue={this.state.kbktStat}
										value={this.state.kbktStat}
										name="kabkota"
										options={this.state.frmKbkt}
										placeholder="Pilih Kabupaten/Kota"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kecamatan
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kecamatan', 'kec', 'keldes');
										}}
										defaultValue={this.state.kecStat}
										value={this.state.kecStat}
										name="kecamatan"
										options={this.state.frmKec}
										placeholder="Pilih Kecamatan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kelurahan / {JENIS_DESA}
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'keldes');
										}}
										// defaultValue={this.state.kldsStat}
										// value={this.state.kldsStat}
										defaultValue={this.state.kelStat}
										value={this.state.kelStat}
										name="keldes"
										options={this.state.frmKel}
										placeholder={'Pilih Kelurahan/' + JENIS_DESA}
										isClearable
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									No. HP
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_hp"
										type="number"
										placeholder="Masukkan Nomor HP"
										required
										defaultValue={this.state.dt.no_hp}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Email
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_h2"
										type="email"
										placeholder="Masukkan e-mail"
										required
										defaultValue={this.state.dt.email}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Transaksi;
