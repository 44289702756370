import React, { Component } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import CountUp from "react-countup";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// koneksi
import { Post, Get, Delete, cekLogin } from "../function/Koneksi";
import { DataToken } from "../function/Format";

const jwt = require("jsonwebtoken");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // layanan: [],
      // perizinan: [],
      menuUtama: "inherit",
      menuLaporan: "none",
      akun: DataToken(sessionStorage.getItem("access_token")),
    };
  }

  getData = (fil = null) => {
    // Get('jenis-surat/list', null, (data) => {
    //   let layanan = [];
    //   let perizinan = [];
    //   data.results.map((dt) => {
    //     if (dt.kategori == 'Layanan Masyarakat') {
    //       layanan.push(dt);
    //     }
    //     if (dt.kategori == 'Perizinan Masyarakat') {
    //       perizinan.push(dt);
    //     }
    //   });
    //   this.setState({ layanan, perizinan });
    // });
  };

  bukaMenu = (wil) => {
    switch (wil) {
      case "laporan":
        this.setState({ menuUtama: "none", menuLaporan: "inherit" });
        break;

      case "utama":
        this.setState({
          menuLaporan: "none",
          menuUtama: "inherit",
        });
        break;
    }
  };

  // =================================================

  componentDidMount() {
    // this.getData();
  }

  render() {
    return (
      <div>
        {/* <Breadcrumb title="Dashboard" parent="Admin" /> */}

        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={() => {
            this.setState({
              show: false,
            });
          }}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0 m-0">
              <div className="card col-lg-12 p-10 bg-light">
                <div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "start",
                    }}
                  >
                    <div className="col-sm-3 mb-4">
                      <Link to={{ pathname: "agen" }}>
                        <div className="card o-hidden mb-0">
                          <div
                            className="card-body p-3 mr-0 text-white"
                            style={{ backgroundColor: "#2e99b0" }}
                          >
                            <div className="media static-top-widget">
                              <div
                                className="media-body p-0"
                                style={{
                                  lineHeight: "1.5em",
                                  minHeight: "6.5em",
                                  height: "100%",
                                  fontWeight: "bold",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="m-0 p-0"
                                  style={{ fontSize: "18px" }}
                                >
                                  Agen
                                </span>
                                <i className="icofont icofont-users icon-bg text-white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-3 mb-4">
                      <Link to={{ pathname: "bumdes" }}>
                        <div className="card o-hidden mb-0">
                          <div
                            className="card-body p-3 mr-0 text-white"
                            style={{ backgroundColor: "#2e99b0" }}
                          >
                            <div className="media static-top-widget">
                              <div
                                className="media-body p-0"
                                style={{
                                  lineHeight: "1.5em",
                                  minHeight: "6.5em",
                                  height: "100%",
                                  fontWeight: "bold",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="m-0 p-0"
                                  style={{ fontSize: "18px" }}
                                >
                                  BUMDES
                                </span>
                                <i className="icofont icofont-users icon-bg text-white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-3 mb-4">
                      <Link to={{ pathname: "transaksi" }}>
                        <div className="card o-hidden mb-0">
                          <div
                            className="card-body p-3 mr-0 text-white"
                            style={{ backgroundColor: "#2e99b0" }}
                          >
                            <div className="media static-top-widget">
                              <div
                                className="media-body p-0"
                                style={{
                                  lineHeight: "1.5em",
                                  minHeight: "6.5em",
                                  height: "100%",
                                  fontWeight: "bold",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="m-0 p-0"
                                  style={{ fontSize: "18px" }}
                                >
                                  Transaksi
                                </span>
                                <i className="icofont icofont-users icon-bg text-white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-3 mb-4">
                      <Link to={{ pathname: "pengguna" }}>
                        <div className="card o-hidden mb-0">
                          <div
                            className="card-body p-3 mr-0 text-white"
                            style={{ backgroundColor: "#2e99b0" }}
                          >
                            <div className="media static-top-widget">
                              <div
                                className="media-body p-0"
                                style={{
                                  lineHeight: "1.5em",
                                  minHeight: "6.5em",
                                  height: "100%",
                                  fontWeight: "bold",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="m-0 p-0"
                                  style={{ fontSize: "18px" }}
                                >
                                  Pengguna
                                </span>
                                <i className="icofont icofont-users icon-bg text-white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
